import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { addFaq } from "../api/actions/mutation";
import { messageShow } from '../../common/api/actions';
import {
  Button,
  TextField,
  Typography,
  Container,
} from "@mui/material";

const styles = (theme) => ({
  container: {
    padding: theme.spacing(4),
    maxWidth: 600,
    margin: "0 auto",
  },
  input: {
    margin: theme.spacing(1, 0),
  },
});

class CreateFaq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPrompt: "",
      newAnswer: "",
    };
  }

  handleAdd = () => {
    const { newPrompt, newAnswer } = this.state;
    if (newPrompt.trim() !== "" && newAnswer.trim() !== "") {
      this.props.addFaq({ prompt: newPrompt, answer: newAnswer }).then(() => {
        this.props.messageShow("FAQ added successfully.");
        this.props.history.push("/faq"); 
      });
    } else {
      this.props.messageShow("Prompt and Answer cannot be empty.");
    }
  };

  handleBack = () => {
    this.props.history.push("/faq"); 
  };

  render() {
    const { classes } = this.props;
    const { newPrompt, newAnswer } = this.state;

    return (
      <Container className={classes.container}>
        <Typography variant="h6" className={classes.title}>Add New FAQ</Typography>
        <TextField
          type="text"
          placeholder="Enter prompt"
          value={newPrompt}
          onChange={(e) => this.setState({ newPrompt: e.target.value })}
          fullWidth
          className={classes.input}
        />
        <TextField
          type="text"
          placeholder="Enter answer"
          value={newAnswer}
          onChange={(e) => this.setState({ newAnswer: e.target.value })}
          fullWidth
          className={classes.input}
        />
        <Button
          variant="outlined"
          color="primary"
          onClick={this.handleAdd}
          className={classes.button}
        >
          Add FAQ
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={this.handleBack}
          className={classes.backButton}
        >
          Back
        </Button>
      </Container>
    );
  }
}
CreateFaq.propTypes = {
  addFaq: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect(null, { addFaq, messageShow })(withStyles(styles)(CreateFaq));
