import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { connect } from 'react-redux';
import { getAllEarnedPoints } from "../api/actions/query";
import { messageShow } from '../../../common/api/actions';

class BarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        chart: {
          type: 'bar',
        },
        title: {
          text: 'Top Earners by Goals',
        },
        xaxis: {
          categories: [],
        },
      },
      series: [
        {
          name: 'Number of Goals',
          data: [],
        },
      ],
    };
  }

  componentDidMount() {
    this.refresh();
  }

  refresh = async () => {
    const { getAllEarnedPoints, messageShow, isLoading } = this.props;
    this.isLoadingToggle(isLoading);
    try {
      const { data } = await getAllEarnedPoints();
      if (data.success) {
        const categories = data.data.map(item => item.user.name);
        const goals = data.data.map(item => item.totalGoalsUsed);
        
        this.setState({
          options: {
            ...this.state.options,
            xaxis: {
              categories: categories,
            },
          },
          series: [
            {
              name: 'Number of Goals',
              data: goals,
            },
          ],
        });
      } else {
        messageShow(data.message);
      }
    } catch (error) {
      messageShow('There was some error. Please try again.');
    } finally {
      this.isLoadingToggle(false);
    }
  }

  isLoadingToggle = (loading) => {
    // Handle the loading state here
    // console.log('Loading state:', loading);
  }

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="bar"
      />
    );
  }
}

BarChart.propTypes = {
  getAllEarnedPoints: PropTypes.func,
  messageShow: PropTypes.func,
  isLoading: PropTypes.bool, // Add isLoading if it's part of props
  classes: PropTypes.object,
};

const mapDispatchToProps = {
  messageShow,
  getAllEarnedPoints,
};

export default connect(null, mapDispatchToProps)((BarChart));

