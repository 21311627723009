import axios from 'axios';
import { API_URL } from '../../../../setup/config/env';

export const createEvent = async (formData) => {
    try {
        const response = await axios.post(`${API_URL}/api/messaging`, formData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const { imagePath, ...responseData } = response.data;

        if (responseData.event || responseData.notification || responseData.post) {
            return {
                imagePath,
                event: responseData.event ? { ...responseData.event, type: 'email' } : null,
                notification: responseData.notification ? { ...responseData.notification, type: 'push-notification' } : null,
                post: responseData.post ? { ...responseData.post, type: 'fb-post' } : null
            };
        } else {
            return { imagePath, ...responseData, type: formData.notificationType };
        }
    } catch (error) {
        if (error.response && error.response.data) {
            return { error: error.response?.data?.error || "An unknown error occurred" };
        }
        console.log(error);
        return { error: "An unknown error occurred. Please try again!" };
    }
};

export const sendEmail = async (id, customerType) => {
    try {
        const response = await axios.post(`${API_URL}/api/messaging/email/${id}/send`, {
            customerType: customerType
        });
        return response.data;
    } catch (error) {
        return { error: error.response?.data?.error || "An unknown error occurred" };
    }
};

export const cancelSend = async (id) => {
    try {
        const response = await axios.post(`${API_URL}/api/messaging/email/${id}/cancel`);
        return response.data;
    } catch (error) {
        return { error: error.response?.data?.error || "An unknown error occurred" };
    }
};

export const deleteEvent = async (ids) => {
    try {
        const response = await axios.delete(`${API_URL}/api/messaging/email/deleteMany`, { data: ids });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Network Error, Please try again');
    }
};

export const updateEmail = async (id, formData) => {
    try {
        const response = await axios.put(`${API_URL}/api/messaging/email/${id}`,formData,{
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error updating email:", error);
        return { error: error.response?.data?.message || "All email IDs you provided are invalid. Please provide valid email IDs" };
    }
};

export const sendNotification = async (id, customerType) => {
    try {
        const response = await axios.post(`${API_URL}/api/messaging/push-notification/${id}/send`, {
            customerType: customerType
        });
        return response.data;
    } catch (error) {
        return { error: error.response?.data?.error || "An unknown error occurred" };
    }
};

export const deleteNotification = async (ids) => {
    try {
        const response = await axios.delete(`${API_URL}/api/messaging/push-notification/deleteMany`, { data: ids });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Network Error, Please try again');
    }
};

export const updateNotification = async (id, formData) => {
    try {
        const response = await axios.put(`${API_URL}/api/messaging/push-notification/${id}`, formData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data
    } catch (error) {
        console.error('Error in updateNotification:', error);
        return { error: error.response?.data?.error || "All email IDs you provided are invalid. Please provide valid email IDs" };
    }
};

export const sendFbPost = async (id) => {
    try {
        const response = await axios.post(`${API_URL}/api/messaging/fb-post/${id}/send`);
        return response.data;
    } catch (error) {
        return { error: error.response?.data?.error || "An unknown error occurred" };
    }
};

export const deletePost = async (ids) => {
    try {
        const response = await axios.delete(`${API_URL}/api/messaging/fb-post/deleteMany`, { data: ids });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Network Error, Please try again');
    }
};

export const updatePost = async (id, formData) => {
    try {
        const response = await axios.put(`${API_URL}/api/messaging/fb-post/${id}`, formData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        return { error: error.response?.data?.error || error };
    }
};


export const deleteSpamByEmail = async (email) => {
    try {
        const response = await axios.delete(`${API_URL}/api/messaging/spam/${email}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Network Error, Please try again');
    }
};

export const deleteBouncedByEmail = async (email) => {
    try {
        const response = await axios.delete(`${API_URL}/api/messaging/bounced/${email}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Network Error, Please try again');
    }
};

export const deleteAllSpam = async () => {
    try {
        const response = await axios.delete(`${API_URL}/api/messaging/spam/all`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Network Error, Please try again');
    }
};

export const deleteAllBounced = async () => {
    try {
        const response = await axios.delete(`${API_URL}/api/messaging/bounced/all`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Network Error, Please try again');
    }
};
