import axios from "axios";
import { messageShow } from "../../../common/api/actions";
import { API_URL } from "../../../../setup/config/env";
import params from '../../../../setup/config/params';


export const getAllRatingFeedbacks = ({ page }) => async (dispatch) => {
  try {
    // Request feedbacks for a specific page
    const response = await axios.get(`${API_URL}/api/rating`, {
      params: { page } // Add pagination query parameter
    });

    if (response.data.success) {
      return response.data; // Return the data with feedbacks and totalCount
    } else {
      dispatch(
        messageShow(response.data.message || "Failed to fetch feedbacks.")
      );
    }
  } catch (error) {
    console.error("Error fetching feedbacks:", error);
    dispatch(messageShow("An error occurred while fetching feedbacks."));
  }
};


export function detail({ userId }) {
  return (dispatch) => {
    return axios.post(API_URL, {
      operation: "userDetail",
      params: { userId },
    });
  };
}
