import React, { useState, useEffect } from "react";
import { Dialog, DialogContent,IconButton, Tooltip } from "@mui/material";
import { Container, Row, Col,Tab, Tabs } from "react-bootstrap";
import Events from "./components/Events";
import {Announcement, Block, Delete, Add as AddIcon} from "@mui/icons-material";
import "./styles.css";
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import CreateEdit from "./CreateEdit";
import ViewEvent from "./components/ViewEvent";
import EmailReportTemplate from "./components/EmailReportTemplate";
import { useDispatch } from 'react-redux'
import { messageShow } from "../common/api/actions"
import useEvents from "./hooks/useEvents"
const Marketing = () => {
    const [refresh, setRefresh] = useState(0);
    const [newEvent, setNewEvent] = useState(null);
    const [popUp, openPopUp] = useState(false);
    const location = useLocation();
    const history = useHistory();
    const [currTab, setCurrTab] = useState(() => {
        const path = location.pathname;
        if (path.includes('/fb-post')) return "fb-post";
        if (path.includes('/push-notification')) return "push-notification";
        return "email";
    });
    const [inProgressEvents, setInProgressEvents] = useState(() => {
        if (!sessionStorage.getItem('sessionActive')) {
            localStorage.removeItem('inProgressemail');
            localStorage.removeItem('inProgressfb-post');
            localStorage.removeItem('inProgresspush-notification');
            sessionStorage.setItem('sessionActive', 'true');
            return {
                email: null,
                'fb-post': null,
                'push-notification': null
            };
        } else {
            return {
                email: localStorage.getItem('inProgressemail') || null,
                'fb-post': localStorage.getItem('inProgressfb-post') || null,
                'push-notification': localStorage.getItem('inProgresspush-notification') || null
            };
        }
    });
    const [selectedEvents, setSelectedEvents]= useState([])

    useEffect(() => {
        const path = location.pathname;
        if (path.includes('/fb-post')) setCurrTab("fb-post");
        else if (path.includes('/push-notification')) setCurrTab("push-notification");
        else if (path.includes('/email')) setCurrTab("email");
    }, [location.pathname]);
    useEffect(()=>{
        setSelectedEvents([])
    },[currTab])

    const setInProgressEventId = (eventType, id) => {
        setInProgressEvents(prev => ({
            ...prev,
            [eventType]: id
        }));
        if (id) {
            localStorage.setItem(`inProgress${eventType}`, id);
        } else {
            localStorage.removeItem(`inProgress${eventType}`);
        }
    };

    const editRoute = () => {
        return location.pathname.includes('/edit/');
    };
    const isReportPage = () => {
        return location.pathname === "/messaging/bounced-email-ids" || location.pathname === "/messaging/spam-reports";
    };

    const refreshList = () => {
        setRefresh((prev) => prev + 1);
    };

    const addNewEvent = (event) => {
        setNewEvent(event);
    };

    const openCreatePopUp = () => {
        openPopUp(true);
    };

    const closePopup = () => {
        openPopUp(false);
    };
    const openBouncedEmails= ()=>{
        history.push("/messaging/bounced-email-ids");
    }

    const openSpamEmails= ()=>{
        history.push("/messaging/spam-reports");
    }

    const tabChange = (key) => {
        setCurrTab(key);
    };

    const updateExistingEvent = (updatedEvent) => {
        refreshList();
    };

    const isMessagingRoute = location.pathname === "/messaging";
    const dispatch = useDispatch();
    const { handleDeleteEvent } = useEvents(currTab, refresh, newEvent, inProgressEvents[currTab], setInProgressEventId);

    const handleDeleteSelected = async () => {
        if (selectedEvents.length === 0) {
            dispatch(messageShow("No events selected for deletion."));
            return;
        } else if (!window.confirm("Do you want to delete the selected events?")) {
            return;
        }
        
        const response = await handleDeleteEvent(selectedEvents);
        if (response===undefined) {
            setSelectedEvents([])
            refreshList()
            dispatch(messageShow("Selected events deleted successfully."));
        } else {
            dispatch(messageShow("Error deleting selected events."));
        }
    };

    return (
        <>
            <Container fluid>
                <Row className="mt-3">
                    <Col style={{ padding: 0, minWidth: '00px', position: 'relative' }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-end',
                        }}>
                            {!isReportPage() && (
                                <Tabs
                                    activeKey={currTab}
                                    onSelect={tabChange}
                                    transition={false}
                                    style={{ flex: 1, paddingRight: '48px' }}
                                    disabled={editRoute() || !isMessagingRoute}
                                >
                                    <Tab eventKey="email" title={<span style={{ fontSize: "15px" }}>Email</span>} disabled={editRoute() || !isMessagingRoute}/>
                                    <Tab eventKey="push-notification" title={<span style={{ fontSize: "15px" }}>Push Notification</span>} disabled={editRoute() || !isMessagingRoute}/>
                                    <Tab eventKey="fb-post" title={<span style={{ fontSize: "15px" }}>Facebook Post</span>} disabled={editRoute() || !isMessagingRoute}/>
                                </Tabs>
                            )}
                            {!editRoute() && !isReportPage() && (
                                <div style={{
                                    position: 'absolute',
                                    right: '80px',
                                    bottom: '0',
                                    paddingBottom: '8px',
                                }}>
                                    {location.pathname === "/messaging" && (
                                        <>
                                            {selectedEvents.length > 0 ? (
                                                <Tooltip title="Delete Selected Events">
                                                    <IconButton
                                                        color="error"
                                                        onClick={handleDeleteSelected}
                                                        size="small"
                                                        aria-label="delete selected events"
                                                        disabled={editRoute() || isReportPage()}
                                                        style={{ marginRight: '15px' }}
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title="Create Event">
                                                    <IconButton
                                                        color="primary"
                                                        onClick={openCreatePopUp}
                                                        size="small"
                                                        aria-label="create event"
                                                        disabled={editRoute() || isReportPage()}
                                                        style={{ marginRight: '15px' }}
                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            <Tooltip title="Spam report" >
                                                <IconButton
                                                    color="error"
                                                    onClick={openSpamEmails}
                                                    size="small"
                                                    aria-label="spam report"
                                                    disabled={currTab !== "email"}
                                                    style={{ marginRight: '15px' }}
                                                >
                                                    <Block />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Bounced emails">
                                                <IconButton
                                                    color="warning"
                                                    onClick={openBouncedEmails}
                                                    size="small"
                                                    aria-label="bounced email"
                                                    disabled={currTab !== "email"}
                                                    style={{ marginRight: '15px' }}
                                                >
                                                    <Announcement />
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Switch>
                            <Route exact path="/messaging">
                                <>
                                    {currTab === "email" && (
                                        <Events
                                            eventType={"email"}
                                            refresh={refresh}
                                            newEvent={newEvent}
                                            inProgressEventId={inProgressEvents.email}
                                            setInProgressEventId={(id) => setInProgressEventId('email', id)}
                                            selectedEvents={selectedEvents}
                                            setSelectedEvents={setSelectedEvents}
                                        />
                                    )}
                                    {currTab === "fb-post" && (
                                        <Events
                                            eventType={"fb-post"}
                                            refresh={refresh}
                                            newEvent={newEvent}
                                            inProgressEventId={inProgressEvents['fb-post']}
                                            setInProgressEventId={(id) => setInProgressEventId('fb-post', id)}
                                            selectedEvents={selectedEvents}
                                            setSelectedEvents={setSelectedEvents}
                                        />
                                    )}
                                    {currTab === "push-notification" && (
                                        <Events
                                            eventType={"push-notification"}
                                            refresh={refresh}
                                            newEvent={newEvent}
                                            inProgressEventId={inProgressEvents['push-notification']}
                                            setInProgressEventId={(id) => setInProgressEventId('push-notification', id)}
                                            selectedEvents={selectedEvents}
                                            setSelectedEvents={setSelectedEvents}
                                        />
                                    )}
                                </>
                            </Route>
                            <Route path="/messaging/:type/edit/:id">
                                <CreateEdit
                                    refreshList={refreshList}
                                    updateExistingEvent={updateExistingEvent}
                                    onClose={closePopup}
                                />
                            </Route>
                            <Route path="/messaging/:type/view/:id">
                                <ViewEvent />
                            </Route>
                            <Route path="/messaging/bounced-email-ids">
                                <EmailReportTemplate type="bounced"/>
                            </Route>
                            <Route path="/messaging/spam-reports">
                                <EmailReportTemplate type="spam"/>
                            </Route>
                        </Switch>
                    </Col>
                </Row>
            </Container>
            <Dialog open={popUp} onClose={closePopup} fullWidth>
                <DialogContent>
                    <CreateEdit refreshList={refreshList} addNewEvent={addNewEvent} onClose={closePopup} />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Marketing;