import axios from "axios";
import { API_URL } from "../../../../setup/config/env";

export const fetchAnalyticsData = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/api/analytics/${id}`);
        return response;
    } catch (error) {
        return { error: error.response?.data?.error || "Network Error" };
    }
}

export const getPricingData = async () => {
    try {
        const pricingResponse = await axios.get(`${API_URL}/api/analytics/pricing`)
        return { pricingData: pricingResponse.data.pricing, productsData: pricingResponse.data.product };
    } catch (error) {
        return { error: error.response?.data?.error || "Network Error" };
    }
}