import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Button } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import Chart from "react-apexcharts";
import { calculateForecastData } from '../utility/forecasting';
import { calculatePricingData } from '../utility/priceComparison';
import { calculateCLTVData } from '../utility/cltv';
import Tooltip from "@mui/material/Tooltip";
import PriceChangeIcon from '@mui/icons-material/PriceChange';


const getRowColor = (predictionType) => {
  if (predictionType === 'Actual') {
    return { backgroundColor: '#E9FCFC' };
  } else if (predictionType === 'Forecast') {
    return { backgroundColor: '#EBFEEF' };
  }
  return {};
};

const getPriceDifferenceStyle = (difference) => {
  if (difference.startsWith('-')) {
    return { color: "green" };
  } else if (difference === '$0.00') {
    return { color: "black" };
  } else if (difference !== 'N/A') {
    return { color: "red" };
  }
  return {};
};

const AnalyticsChart = ({ data, name, onAlter, storeData }) => {
  const [chartData, setChartData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);

  useEffect(() => {
    if (name !== "pricing" && name !== "CLTV Model") {
      const { chartOptions, chartSeries, tableData } = calculateForecastData(data, name);
      setChartData({ options: chartOptions, series: chartSeries });
      setTableData(tableData);
    } else if (name === 'pricing') {
      const { chartOptions, chartSeries, tableData } = calculatePricingData(data, storeData);
      setChartData({ options: chartOptions, series: chartSeries });
      setTableData(tableData);
    } else if (name === 'CLTV Model') {
      const { chartOptions, chartSeries, tableData } = calculateCLTVData(data)
      setChartData({ options: chartOptions, series: chartSeries });
      setTableData(tableData);
    }
  }, [data, name, storeData]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const paginatedData = tableData.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  return (
    <div>
      {chartData && (
        <div style={{ width: "100%", height: "350px", marginBottom: "20px" }}>
          <Chart options={chartData.options} series={chartData.series} name={name} type="bar" height="100%" />
        </div>
      )}

        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
          <Pagination 
            count={Math.ceil(tableData.length / rowsPerPage)} 
            page={page} 
            onChange={handlePageChange}
            color="primary"
          />
        </div>

      <Table style={{ width: 'auto', borderCollapse: 'collapse', margin: '0 auto' }}>
        <TableHead>
          <TableRow>
            {name !== "pricing" && name !== "CLTV Model" ? (
              <>
                <TableCell style={{width:"200px"}}>Date</TableCell>
                <TableCell style={{width:"200px"}}>Actuals</TableCell>
                <TableCell style={{width:"200px"}}>Forecast</TableCell>
              </>
            ) : name === 'pricing' ? (
              <>
                <TableCell>Items</TableCell>
                <TableCell>Store Price</TableCell>
                <TableCell>Difference</TableCell>
                <TableCell>Min Price</TableCell>
                <TableCell>Max Price</TableCell>
                <TableCell>Average Price</TableCell>
                <TableCell>Actions</TableCell>
              </>
            ) : name==='CLTV Model'?(
              <>
                <TableCell style={{width:"200px"}}>Customer Name</TableCell>
                <TableCell style={{width:"200px"}}>Customer Type</TableCell>
                <TableCell>RFM Score</TableCell>
              </>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedData.map((row, index) => (
            <TableRow key={index} style={name !== "pricing" && name !== "CLTV Model" ? getRowColor(row.predictionType) : {}}>
              {name !== "pricing" && name !== "CLTV Model" ? (
                <>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.actuals}</TableCell>
                  <TableCell>{row.forecast}</TableCell>
                </>
              ) : name === 'pricing' ? (
                <>
                  <TableCell>{row.productName}</TableCell>
                  <TableCell>{row.price}</TableCell>
                  <TableCell style={{ padding: '12px', ...getPriceDifferenceStyle(row.priceDifference) }}>
                    {row.priceDifference}
                  </TableCell>
                  <TableCell>{row.minPrice}</TableCell>
                  <TableCell>{row.maxPrice}</TableCell>
                  <TableCell>{row.averagePrice}</TableCell>
                  <TableCell>
                  <Tooltip title="Alter store price">
                    <Button variant="text" color="primary" onClick={() => onAlter(row)}>
                      <PriceChangeIcon fontSize="large" />
                    </Button>
                  </Tooltip>
                  </TableCell>
                </>
              ) : name === 'CLTV Model' ? (
                <>
                  <TableCell>{row.customerName}</TableCell>
                  <TableCell>{row.segment}</TableCell>
                  <TableCell style={{textAlign:"center"}}>{row.rfmSegment}</TableCell>
                </>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default AnalyticsChart;