import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { messageShow } from "../../common/api/actions";
import { TableRow, IconButton, Tooltip,TableCell, Checkbox } from "@mui/material";
import { Delete, Send, FileCopy } from "@mui/icons-material";
import { NameTableCell, MessageTableCell, DateTableCell, StatusTableCell, ActionTableCell } from "./tableCells";
import { useHistory } from "react-router-dom";
import { createEvent } from "../api/action/mutation";
import routes from "../api/routes";

const EventCard = ({ event, handleDelete, inProgressEventId, setInProgressEventId, sendFunction, refreshFunction, handelCancel, eventType, fetchEvents, updateEvent, selectedEvents, setSelectedEvents }) => {
    const [status, setStatus] = useState(null);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        setStatus(event.status);
    }, [event]);
    const handleSelect = (e) => {
        e.stopPropagation();
        const isChecked = e.target.checked;
        if (isChecked) {
            setSelectedEvents((prev) => [...prev, event._id]);
        } else {
            setSelectedEvents((prev) => prev.filter(id => id !== event._id));
        }
    };

    const handleRowClick = () => {
        if(status==="Yet to Send"){
            history.push({
                    pathname: routes.editEvent.path(eventType, event._id),
                    state: { eventData: event }
                });
        }
        else
            history.push({
                pathname: routes.viewEvent.path(eventType, event._id),
                state: { eventData: event }
        });
    };

    const handleActionClick = (e, action) => {
        e.stopPropagation();
        if (action) action();
    };

    const handleSend = async () => {
        if (inProgressEventId) {
            dispatch(messageShow(`Another ${eventType} event is already in progress`));
            return;
        }
        setStatus("In Progress")
        event.status = "In Progress"
        try {
            setInProgressEventId(event._id);
            const response = await sendFunction(event._id);
            if (response.message==="Batch Attached" && eventType==="email") {
                dispatch(messageShow(response.message));
            }
            else if(response.message==="Completed"){
                setStatus("Completed");
                dispatch(messageShow(response.message));
            }
             else{
                setStatus("Failed")
                dispatch(messageShow(response.error));
            }
        } catch (error) {
            console.error("Error sending event:", error);
            dispatch(messageShow("Error sending event. Please try again."));
        } finally {
            setInProgressEventId(null);
        }
    };

    const handleDeleteEvent = async () => {
        if (window.confirm(`Do you want to delete: ${event.name}?`)) {
            await handleDelete(event._id);
            setSelectedEvents((prev) => prev.filter(id => id !== event._id));
        }
    };

    const handleClone = async () => {
        try {
            const clonedEventData = {
                name: `${event.name} (Clone)`,
                message: event.message,
                status: "Yet to Send",
                date: new Date().toISOString(),
                notificationType: eventType
            };
            if (eventType === 'email') {
                clonedEventData.subject = event.subject;
                clonedEventData.greeting = event.greeting;
            }
            if (eventType !== 'push-notification') {
                clonedEventData.eventImg = event.eventImg;
            }
            if (eventType !== 'fb-post') {
                clonedEventData.for = event.for;
                clonedEventData.customerJoinDays = event.for === 'age' ? event.customerJoinDays : 0;
            }
            if(event.for==='custom'){
                clonedEventData.emails = event.customEmails
            } 
            const cloneResponse = await createEvent(clonedEventData);
            if (cloneResponse && !cloneResponse.error) {
                if (cloneResponse.event || cloneResponse.notification || cloneResponse.post) {
                    if (cloneResponse.event) updateEvent(cloneResponse.event)
                    if (cloneResponse.notification) updateEvent(cloneResponse.notification);
                    if (cloneResponse.post) updateEvent(cloneResponse.post)
                } else {
                    // console.log(cloneResponse);
                    updateEvent(cloneResponse.data);
                }
                dispatch(messageShow("Event cloned successfully"));
            } else {
                throw new Error(cloneResponse.error || "Error cloning event");
            }
        } catch (error) {
            console.error("Error cloning event:", error);
            dispatch(messageShow("Error cloning event. Please try again."));
        }
    };

    const renderStatus = () => {
        const getStyle = (color) => ({
            padding: '4px 8px',
            borderRadius: '5px',
            fontSize: '12px',
            border: `1px solid ${color}`,
            backgroundColor: `${color}20`,
            color: color, width: '80px',
            height: '24px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center'
        });

        switch (status) {
            case "In Progress":
                return <span style={getStyle("#42a5f5")}>In Progress</span>;
            case "Cancelled":
                return <span style={getStyle("#ffa726")}>Cancelled</span>;
            case "Yet to Send":
                return <span style={getStyle("#78909c")}>Not Sent</span>;
            case "Completed":
                return <span style={getStyle("#66bb6a")}>Sent</span>;
            case "Failed":
                return <span style={getStyle("#ef5350")}>Failed</span>;
            default:
                return <span style={getStyle("#ef5350")}>Failed</span>;
        }
    };

    const setActionButtons = () => {
        const isYetToSend = status === "Yet to Send";
        const isInProgress = status === "In Progress";

        const buttons = {
            send: (
                <Tooltip title={isInProgress ? `This ${eventType} event is in progress` : "Send"}>
                    <span>
                        <IconButton
                            aria-label="send"
                            size="medium"
                            onClick={(e) => handleActionClick(e, handleSend)}
                            disabled={!isYetToSend || inProgressEventId}
                        >
                            <Send color={!isYetToSend || inProgressEventId ? "disabled" : "primary"} />
                        </IconButton>
                    </span>
                </Tooltip>
            ),
            clone: (
                <Tooltip title="Clone">
                    <span>
                        <IconButton
                            aria-label="clone"
                            size="medium"
                            onClick={(e) => handleActionClick(e, handleClone)}
                        >
                            <FileCopy color="primary" />
                        </IconButton>
                    </span>
                </Tooltip>
            ),
            delete: (
                <Tooltip title="Delete">
                    <span>
                        <IconButton
                            aria-label="delete"
                            size="medium"
                            onClick={(e) => handleActionClick(e, handleDeleteEvent)}
                        >
                            <Delete color="error" />
                        </IconButton>
                    </span>
                </Tooltip>
            ),
        };

        return (
            <div style={{ display: "flex", gap: "5px", justifyContent: "center" }}>
                {buttons.send}
                {buttons.clone}
                {/* {buttons.delete} */}
            </div>
        );
    };

    return (
        <TableRow key={event._id} onClick={handleRowClick} style={{ cursor: 'pointer' }} hover>
            <TableCell padding="checkbox">
                <Checkbox
                    onClick={(e) => e.stopPropagation()}
                    checked={selectedEvents.includes(event._id)}
                    onChange={handleSelect}
                />
            </TableCell>
            <NameTableCell>{event.name}</NameTableCell>
            <MessageTableCell title={event.message}>{event.message}</MessageTableCell>
            <DateTableCell>{new Date(event.date).toLocaleDateString()}</DateTableCell>
            <StatusTableCell>{renderStatus()}</StatusTableCell>
            <ActionTableCell onClick={(e) => e.stopPropagation()}>
                {setActionButtons()}
            </ActionTableCell>
        </TableRow>
    );
};

export default EventCard;