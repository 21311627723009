import { withStyles } from "@material-ui/core";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Anchor from "@material-ui/core/Link";
import InputBase from "@material-ui/core/InputBase";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { Tooltip } from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import PrintIcon from "@mui/icons-material/Print";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@material-ui/icons/Delete";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search'
import { IconButton } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// UI Imports
import Toolbar from "@material-ui/core/Toolbar";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// App Imports
import params from "../../../setup/config/params";
import { messageShow } from "../../common/api/actions";
import EmptyMessage from "../../common/EmptyMessage";
import Loading from "../../common/Loading";
import Pagination from "../../common/Pagination";
import SectionPaper from "../../common/SectionPaper";
import {
  cancel,
  deliverToggle,
  dispatchToggle,
  paidToggle,
  remove,
  orderacceptedToggle,
} from "../api/actions/mutation";
import { list } from "../api/actions/query";
import routes from "../api/routes";
import { updateSettings } from "../../setting/api/actions/mutation";
import styles from "./styles";
import { FormControlLabel, Switch } from "@mui/material";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import DriveEtaOutlinedIcon from "@mui/icons-material/DriveEtaOutlined";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; 

dayjs.extend(utc); 

// Function to convert UTC date to 12-hour format without changing the time
const convertUTCToLocal12HourFormat = (utcDate) => {
  // Parse the UTC date string to a dayjs object
  const date = dayjs.utc(utcDate);

  // Format the date and time to 'DD-MM-YYYY hh:mm:ss a' (12-hour format)
  // Using dayjs format directly
  const formattedDate = date.format('DD-MM-YYYY hh:mm:ss A');

  return formattedDate;
};


// Component
class List extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      orders: [],
      count: 0,
      page: props.match.params.page || 1,
      search: "",
      datesearch: "",
      idsearch: "",
      mobilesearch: "",
      settingsData: null,
      resumeOrder: false,
      taxAmount: "",
      currency: "",
      currencydata: "",
      selectedSearchType: "", 
    };

    this.changeSearch = debounce(this.refresh, 100);
    this.settingsId = window.localStorage.getItem("user")
      ? JSON.parse(window.localStorage.getItem("user")).settingsId
      : null;
  }

  componentDidMount() {
    this.refresh();
  }

  componentWillReceiveProps(nextProps) {
    const { match } = nextProps;
    this.setState(
      {
        page: match.params.page,
      },
      this.refresh
    );
  }

  refresh = async (isLoading = true) => {
    const { list, messageShow } = this.props;
    const { page, search, datesearch, idsearch, mobilesearch, selectedSearchType } = this.state;
    this.isLoadingToggle(isLoading);
  
    try {
      const filters = {
        page,
        filter: {
          search: selectedSearchType === "search" ? search : "",
          datesearch: selectedSearchType === "datesearch" ? datesearch : "",
          idsearch: selectedSearchType === "idsearch" ? idsearch : "",
          mobilesearch: selectedSearchType === "mobilesearch" ? mobilesearch : "",
        },
      };
      const { data } = await list(filters);
      if (data.success) {
        const orders = data.data.list;
        let currencySymbol = data.data.currencydata.symbol;
        var productTotalPrice = orders.map((order) => order.amountTotal);
        productTotalPrice = Number.parseFloat(productTotalPrice);
        let taxpercentage = data.data.settingsData.taxAmount;
        const taxCalculatedAmount = Number.parseFloat(
          (productTotalPrice / 100) * taxpercentage
        ).toFixed(2);
        const grandTotal = Number(
          Number.parseFloat(
            productTotalPrice + parseFloat(taxCalculatedAmount)
          ).toFixed(2)
        );
        // Push grandTotal as new amountTotal for each order
        const updatedOrders = orders.map((order) => ({
          ...order,
          amountTotal: grandTotal,
        }));
  
        this.setState({
          orders: updatedOrders,
          count: data.data.count,
          settingsData: data.data.settingsData,
          resumeOrder: data.data?.settingsData
            ? data.data.settingsData.resumeOrder
            : this.state.resumeOrder,
          currencySymbol: currencySymbol,
        });
      } else {
        messageShow(data.message);
      }
    } catch (error) {
      messageShow(error.message);
    } finally {
      this.isLoadingToggle(false);
    }
  };
  isLoadingToggle = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  onOrderacceptedToggle = (orderId) => async (event) => {
    const isOrderAccepted = event.target.checked;

    let check = window.confirm(
      `Are you sure you want to mark this order as ${
        isOrderAccepted ? "accepted" : "not accepted"
      }?`
    );

    if (check) {
      const { orderacceptedToggle, messageShow, match } = this.props;

      try {
        const { data } = await orderacceptedToggle({
          orderId,
          isOrderAccepted,
        });

        messageShow(data.message);

        if (data.success) {
          this.refresh(match.params.page, false);
        }
      } catch (error) {
        messageShow(error.message); 
      }
    }
  };

  onDeliverToggle = (orderId) => async (event) => {
    const isDelivered = event.target.checked;

    let check = window.confirm(
      `Are you sure you want to mark this order as ${
        isDelivered ? "delivered" : "not delivered"
      }?`
    );

    if (check) {
      const { deliverToggle, messageShow, match } = this.props;

      try {
        const { data } = await deliverToggle({ orderId, isDelivered });

        messageShow(data.message);

        if (data.success) {
          this.refresh(match.params.page, false);
        }
      } catch (error) {
        messageShow(error.message); 
      }
    }
  };

  onDispatchToggle = (orderId) => async (event) => {
    const isDispatched = event.target.checked;

    let check = window.confirm(
      `Are you sure you want to mark this order as ${
        isDispatched ? "Dispatched" : "not Dispatched"
      }?`
    );

    if (check) {
      const { dispatchToggle, messageShow, match } = this.props;

      try {
        const { data } = await dispatchToggle({ orderId, isDispatched });

        messageShow(data.message);

        if (data.success) {
          this.refresh(match.params.page, false);
        }
      } catch (error) {
        messageShow("errorrrrrrrrr", error.message); 
      }
    }
  };

  onPaidToggle = (orderId) => async (event) => {
    const isPaid = event.target.checked;

    let check = window.confirm(
      `Are you sure you want to mark this order as ${
        isPaid ? "paid" : "unpaid"
      }?`
    );

    if (check) {
      const { paidToggle, messageShow, match } = this.props;

      try {
        const { data } = await paidToggle({ orderId, isPaid });
        messageShow(data.message);

        if (data.success) {
          this.refresh(match.params.page, false);
        }
      } catch (error) {
        messageShow(error.message); 
      }
    }
  };

  onCancel = (orderId) => async () => {
    let check = window.confirm("Are you sure you want to cancel this order?");

    if (check) {
      const { cancel, messageShow, match } = this.props;

      try {
        const { data } = await cancel({ orderId });

        messageShow(data.message);

        if (data.success) {
          this.refresh(match.params.page, false);
        }
      } catch (error) {
        messageShow(error.message); 
      }
    }
  };

  onDelete = (orderId) => async () => {
    let check = window.confirm("Are you sure you want to delete this order?");

    if (check) {
      const { remove, messageShow, match } = this.props;

      try {
        const { data } = await remove({ orderId });

        messageShow(data.message);

        if (data.success) {
          this.refresh(match.params.page, false);
        }
      } catch (error) {
        messageShow("Some error occurred. Please try again.");
      }
    }
  };

   onSearch = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      if (value.trim() === "") {
        // Clear search input and navigate back to page 1
        this.setState({ page: 1 }, () => {
          this.props.history.push(routes.orderList.path(1)); 
          this.refresh();
        });
      }
      })
    }
            
    handleSearch = () => {
      this.handleSearchSubmit(this.state);  
    };
    handleSearchSubmit = (state) => {
      const { search, datesearch, idsearch, mobilesearch } = state;
      this.refresh();
    };
    handleDateChange = (date) => {
      const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : '';
      this.setState({ datesearch: formattedDate }, () => {
        if (formattedDate === '') {
          this.props.history.push(routes.orderList.path(1)); 
          this.refresh();
        }
      });
    };
    
  onSearchTypeChange = (event) => {
    this.setState({ selectedSearchType: event.target.value });
  };

  changeOrderResume = async (checked) => {
    this.setState({ resumeOrder: checked });
    const { updateSettings, messageShow } = this.props;
    const { data } = await updateSettings({
      requestObject: { resumeOrder: checked },
      _id: this.state.settingsData
        ? this.state.settingsData?._id
        : this.settingsId,
    });
    messageShow(data.message);
  };

  render() {
    const {
      auth: { user },
      classes,
    } = this.props;
    const {
      isLoading,
      orders,
      count,
      search,
      datesearch,
      idsearch,
      mobilesearch,
      resumeOrder,
      currencySymbol,
      selectedSearchType,
    } = this.state;

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit">
            Orders
          </Typography>
        </Toolbar>
        <Toolbar className={classes.toolbar}>
        <Grid container spacing={2} alignItems="center">
        <Grid item>
          <FormControl variant="outlined" style={{ minWidth: 150 }}>
            <InputLabel id="search-type-label">Search By</InputLabel>
            <Select
              labelId="search-type-label"
              value={selectedSearchType}
              onChange={this.onSearchTypeChange}
              label="Search By"
            >
              <MenuItem value="idsearch">Order ID</MenuItem>
              <MenuItem value="search">Name</MenuItem>
              <MenuItem value="mobilesearch">Mobile</MenuItem>
              <MenuItem value="datesearch">Date</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          {selectedSearchType === "datesearch" ? (
              <DatePicker
                value={datesearch ? dayjs(datesearch) : null}
                onChange={this.handleDateChange}
                slotProps={{ textField: { variant: "standard", sx: { "& .MuiInput-underline:before": { borderBottomColor: "black" }, "& .MuiInput-underline:after": { borderBottomColor: "blue" } } } }}
                renderInput={(params) => (
                  <InputBase
                    {...params}
                    label="Select Date"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarTodayIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
          ) : (
            <TextField
              name={selectedSearchType}
              value={this.state[selectedSearchType] || ""}
              placeholder="Search..."
              onChange={this.onSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={this.handleSearch} size='big' color="primary">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Grid>

        {selectedSearchType === "datesearch" && (
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={this.handleSearch}
              style={{ borderRadius: '5px',
                textTransform: 'none',  
                padding: '2px 13px',     
                fontSize: '0.9rem', 
                }}
            >
              Search
            </Button>
          </Grid>
        )}
      </Grid>
          
          <div className="d-flex flex-row gap-2">
            <Button id="refreshOrder" onClick={this.refresh}>
              Refresh
            </Button>
            <FormControl>
              <FormControlLabel
                control={
                  <Switch
                    checked={resumeOrder}
                    name="resumeOrder"
                    onChange={(event) =>
                      this.changeOrderResume(event.target.checked)
                    }
                    disabled
                  />
                }
                label="Resume Orders"
              />
            </FormControl>
          </div>
        </Toolbar>

        <SectionPaper>
          {isLoading ? (
            <Loading />
          ) : orders.length === 0 ? (
            <EmptyMessage message={"No orders have been placed yet."} />
          ) : (
            <>
              <Table padding={"normal"}>
                <TableHead>
                  <TableRow>
                    <TableCell width={40} align="center"></TableCell>
                    <TableCell width={80}>Order ID</TableCell>
                    <TableCell width={80}>Name</TableCell>
                    <TableCell width={80}>Mobile</TableCell>
                    <TableCell width={80}>Amount</TableCell>
                    <TableCell width={100}>Date</TableCell>
                    {/*<TableCell width={120}>Payment Type</TableCell>*/}
                    {/*<TableCell width={80}>Pincode</TableCell>*/}

                    <TableCell width={40} align="center">
                      Accepted
                    </TableCell>
                    <TableCell width={40} align="center">
                      Paid
                    </TableCell>
                    <TableCell width={40} align="center">
                      Dispatched
                    </TableCell>
                    <TableCell width={40} align="center">
                      Delivered
                    </TableCell>

                    {/* <TableCell width={90} align="center">Cancel</TableCell> */}
                    <TableCell width={240} align="center">
                      Actions
                    </TableCell>
                    {/* <TableCell width={40} align="center">
                      Delete  
                    </TableCell>
                    <TableCell width={40} align="center">
                      Print  
                    </TableCell> */}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {orders.map(
                    ({
                      _id,
                      id,
                      userId,
                      paymentId,
                      amountTotal,
                      isCancelled,
                      type,
                      deliveryMode,
                      paymentMode,
                      isPaid,
                      isDispatched,
                      isDelivered,
                      isOrderAccepted,
                      createdAt,
                      pincodeId,
                      cartrequest: { grandTotal },
                    }) => (
                      <TableRow
                        key={_id}
                        style={
                          !isOrderAccepted &&
                          !isDispatched &&
                          !isDelivered &&
                          !isCancelled &&
                          paymentId.status !== params.payment.status.done.key
                            ? { backgroundColor: "#FECD62" }
                            : { backgroundColor: "inherit" }
                        }
                      >
                        <Tooltip
                          title={
                            deliveryMode === "HOME_DELIVERY"
                              ? "Home Delivery"
                              : deliveryMode === "STORE_PICKUP"
                              ? "Store Pickup"
                              : deliveryMode
                          }
                          placement="top"
                        >
                          <TableCell align="center" style={{ color: "gray" }}>
                            {deliveryMode === "HOME_DELIVERY" ? (
                              <DriveEtaOutlinedIcon />
                            ) : deliveryMode === "STORE_PICKUP" ? (
                              <ShoppingBagOutlinedIcon />
                            ) : (
                              deliveryMode
                            )}
                          </TableCell>
                        </Tooltip>

                        <TableCell>
                          <Link to={routes.orderDetail.path(_id)}>
                            <Anchor
                              component="span"
                              style={{ fontWeight: 500 }}
                              color={isCancelled ? "error" : "primary"}
                            >
                              {id.toUpperCase()}
                            </Anchor>
                          </Link>
                        </TableCell>
                        <TableCell>{[userId?.name]}</TableCell>
                        <TableCell>{[userId?.mobile]}</TableCell>
                        <TableCell>
                          {currencySymbol} {grandTotal}
                        </TableCell>
                        <TableCell>
                          {convertUTCToLocal12HourFormat(createdAt)}                        
                        </TableCell>
                        {/*<TableCell>{params.order.types[type].title}</TableCell>*/}
                        {/*<TableCell>{params.payment.types[paymentId.type] && params.payment.types[paymentId.type].title}</TableCell>*/}
                        {/*<TableCell>{pincodeId ? pincodeId.pincode : ''}</TableCell>*/}

                        <TableCell align="center">
                          <Checkbox
                            checked={isOrderAccepted}
                            onChange={this.onOrderacceptedToggle(_id)}
                            color="primary"
                            disabled={isCancelled || isOrderAccepted}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox
                            checked={paymentId.status === "done"}
                            onChange={this.onPaidToggle(_id)}
                            color="secondary"
                            disabled={
                              paymentId.status === "done" ||
                              isDelivered ||
                              isCancelled
                            }
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox
                            disabled={
                              isCancelled || !isOrderAccepted || isDispatched
                            }
                            checked={isDispatched}
                            onChange={this.onDispatchToggle(_id)}
                            color="primary"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox
                            disabled={
                              isCancelled ||
                              !isOrderAccepted ||
                              paymentId.status !== "done" ||
                              !isDispatched ||
                              isDelivered
                            }
                            checked={isDelivered}
                            onChange={this.onDeliverToggle(_id)}
                            color="primary"
                          />
                        </TableCell>

                        <TableCell>
                          <Tooltip title="Cancel Order">
                            <span>
                              <Button
                                disabled={isCancelled}
                                onClick={this.onCancel(_id)}
                                color="secondary"
                              >
                                <CancelIcon />
                              </Button>
                            </span>
                          </Tooltip>

                          <Tooltip title="Delete order">
                            <Button onClick={this.onDelete(_id)}>
                              <DeleteIcon style={{ color: "gray" }} />
                            </Button>
                          </Tooltip>

                          <Tooltip title="Uber Delivery">
                            <Link to={`/uberDelivery/${_id}`}>
                              <Button style={{ color: "gray" }}>
                                <LocalShippingIcon />
                              </Button>
                            </Link>
                          </Tooltip>

                          <Link to={routes.orderPrint.path(_id)}>
                            <Button style={{ color: "gray" }}>
                              <PrintIcon />
                            </Button>
                          </Link>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
              <Pagination count={count} route={routes.orderSearch} />
            </>
          )}
        </SectionPaper>
      </div>
    );
  }
}

// Component Properties
List.propTypes = {
  list: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  deliverToggle: PropTypes.func.isRequired,
  dispatchToggle: PropTypes.func.isRequired,
  orderacceptedToggle: PropTypes.func.isRequired,
  paidToggle: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  updateSettings: PropTypes.func.isRequired,
};
// Component State
function listState(state) {
  return {
    auth: state.auth,
  };
}

export default connect(listState, {
  list,
  cancel,
  remove,
  deliverToggle,
  dispatchToggle,
  orderacceptedToggle,
  paidToggle,
  messageShow,
  updateSettings,
})(withStyles(styles)(List));
