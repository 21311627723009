export const calculateCLTVData = (data) => {
    const counts = {
        Platinum: 0,
        Gold: 0,
        Silver: 0,
        Bronze: 0,
    };

    const tableData = data.map(customer => {
        const segmentName = customer.RFM_Segment_Name;
        counts[segmentName.split(" ")[0]] += 1;

        return {
            customerName: customer.user_name,
            rfmSegment: customer.RFM_Segment,
            segment: segmentName,
        };
    });

    const chartOptions = {
        chart: {
            type: 'line',
            height: 600,
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                    customIcons: []
                }
            },
        },
        // colors: ['#f3722c', '#f9c74f','#43aa8b', '#277da1'],
        xaxis: {
            categories: ['Platinum', 'Gold', 'Silver', 'Bronze'],
        },
        yaxis: {
            title: {
                text: 'Count',
            },
            labels: {
                formatter: function(val) { return Math.round(val) }
            },
        },
        title: {
            text: 'Customer Segmentation',
            align: 'center',
        },
        legend: {
            position: 'top',
        },
        dataLabels: {
            enabled: false
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: function(value) {
                    return value + " customers"
                }
            }
        },
        plotOptions: {
            bar: {
                columnWidth: '40%',
                distributed: true,
            },
        },
    };
    const chartSeries = [
        {
            name: 'Customers',
            data: [counts.Platinum, counts.Gold, counts.Silver, counts.Bronze],
        }
    ];

    return { chartOptions, chartSeries, tableData };
}