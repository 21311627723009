import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchAnalyticsData } from '../api/action/query';
import { messageShow } from '../../common/api/actions';
import Loading from "../../common/Loading"
import AnalyticsChart from './AnalyticsChart';

const ForecastAndCltv = ({ type, chartType }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetchAnalyticsData(type);
        if (response.data && response.data.output) {
          setData(response.data.output);
        } else {
          dispatch(messageShow(`Failed to fetch ${chartType} data`));
        }
      } catch (error) {
        dispatch(messageShow(`An error occurred while fetching ${chartType} data`));
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [type, dispatch, chartType]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <AnalyticsChart  data={data} name={chartType}/>
    </div>
  );
};

export default ForecastAndCltv;