// Imports
import { withStyles } from "@material-ui/core";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import routes from "../api/routes";
import { Link, withRouter } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import PropTypes from "prop-types";
import styles from "./styles";
import Points from "../Points";
import Goals from "../listGoals";
import Rewards from "../listRewards";
import Leaderboard from "../Leaderboard/List";

// Component
class List extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.getActiveTabFromPath(
        props.location.pathname,
        props.location.state
      ),
    };
  }

  getActiveTabFromPath(path, state) {
    if (state && state.activeTab) {
      return state.activeTab;
    }
    if (path.includes(routes.listAllTabs.path)) {
      return "Goals";
    } else if (path.includes(routes.listPoints.path)) {
      return "Points";
    } else if (path.includes(routes.listRewards.path)) {
      return "Rewards";
    } else if (path.includes(routes.listLeaderboard.path)) {
      return "LeaderBoard";
    }
    return "Goals";
  }

  handleSelect = (key) => {
    this.setState({ activeTab: key }, () => {
      if (key === "Goals") {
        this.props.history.push(routes.listAllTabs.path, { activeTab: key });
      } else if (key === "Points") {
        this.props.history.push(routes.listPoints.path, { activeTab: key });
      } else if (key === "Rewards") {
        this.props.history.push(routes.listRewards.path, { activeTab: key });
      } else if (key === "LeaderBoard") {
        this.props.history.push(routes.listLeaderboard.path, {
          activeTab: key,
        });
      }
    });
  };

  render() {
    const { classes } = this.props;
    const { activeTab } = this.state;
    return (
      <section id="menu" className="mt-5">
        <div className="clearfix mb-5 pb-5">
          <Container fluid>
            <Row className="mb-10 justify-content-center">
              <Col>
                <Tabs
                  activeKey={activeTab}
                  onSelect={this.handleSelect}
                  transition={false}
                >
                  <Tab
                    eventKey="Goals"
                    title={<h1 style={{ fontSize: "15px" }}>Goals</h1>}
                    style={{ color: "blue" }}
                  >
                    <Goals />
                  </Tab>
                  <Tab
                    eventKey="Points"
                    title={<h1 style={{ fontSize: "15px" }}>Points</h1>}
                  >
                    <Points />
                  </Tab>
                  <Tab
                    eventKey="Rewards"
                    title={<h1 style={{ fontSize: "15px" }}>Rewards</h1>}
                  >
                    <Rewards />
                  </Tab>
                  <Tab
                    eventKey="LeaderBoard"
                    title={<h1 style={{ fontSize: "15px" }}>LeaderBoard</h1>}
                  >
                    <Leaderboard />
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    );
  }
}

List.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(connect(null, {})(withStyles(styles)(List)));
