// Imports
import { withStyles } from "@material-ui/core";
import Button from "@mui/material/Button";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { PureComponent, useState, Component } from "react";
import { connect } from "react-redux";
import { messageShow } from "../../common/api/actions";
import SectionPaper from "../../common/SectionPaper";
import styles from "./styles";
import InputBase from "@material-ui/core/InputBase";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid/Grid";
import { nullToEmptyString } from "../../../setup/helpers";
import { getPoints } from "../../rewards/apiPoints/actions/query";
import { updatePoints } from "../../rewards/apiPoints/actions/mutation";

// Component
class Save extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSubmitting: false,
      PointsData: {},
    };
  }

  async componentDidMount() {
    this.refresh();
  }

  refresh = async () => {
    const { getPoints, messageShow } = this.props;

    this.isLoadingToggle(true);

    try {
      const { data } = await getPoints();
      if (data.success && data.data) {
        this.setState({
          PointsData: data.data[0],
        });
      } else {
        console.error("data.success is false or data.data is missing");
      }
    } catch (error) {
      // console.log( error);
      messageShow("Some error occurred. Please try again.");
    } finally {
      this.isLoadingToggle(false);
    }
  };

  isLoadingToggle = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  isSubmittingToggle = (isSubmitting) => {
    this.setState({
      isSubmitting,
    });
  };

  onChange = ({ target: { name, value } }) => {
    const { PointsData } = this.state;
    PointsData[name] = value;
    this.setState({
      PointsData,
    });
  };

  savePoints = async () => {
    const { points, value } = this.state.PointsData;
    const { updatePoints, messageShow } = this.props;
    const { _id } = this.state.PointsData;
    const { data } = await updatePoints({ _id, points, value });
    this.isSubmittingToggle(false);
    messageShow(data.message);
  };

  render() {
    const { isSubmitting, PointsData } = this.state;

    return (
      <div>
        <div style={{ padding: "18px" }}>
          <Grid item xs={12}>
            <SectionPaper>
              <Table padding={"normal"}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 50 }}>Points</TableCell>
                    <TableCell style={{ width: 100 }}>
                      <InputBase
                        placeholder={"Points"}
                        type="number"
                        variant="standard"
                        name={"points"}
                        value={nullToEmptyString(PointsData.points)}
                        onChange={this.onChange}
                        label={"points"}
                        margin={"dense"}
                        autoComplete={"off"}
                        fullWidth
                        inputProps={{
                          pattern: "[0-9]*", // Allows only numeric input
                          inputMode: "numeric", // Specifies the numeric input mode
                          min: "0", // Ensures only positive numbers are allowed
                        }}
                      />
                    </TableCell>
                    <TableCell style={{ width: 140 }}>= Dollar Value</TableCell>
                    {/* <TableCell style={{  width: 10 }}>$</TableCell> */}
                    {/* <TableCell style={{  width: 10 }}>Value </TableCell> */}
                    <TableCell style={{ width: 100 }}>
                      <InputBase
                        variant="standard"
                        name={"value"}
                        value={nullToEmptyString(PointsData.value)}
                        onChange={this.onChange}
                        label={"value"}
                        margin={"dense"}
                        autoComplete={"off"}
                        fullWidth
                        placeholder={"value"}
                        type="number"
                        inputProps={{
                          pattern: "[0-9]*", // Allows only numeric input
                          inputMode: "numeric", // Specifies the numeric input mode
                          min: "0", // Ensures only positive numbers are allowed
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        aria-label={"Save"}
                        size="medium"
                        color={"primary"}
                        disabled={isSubmitting}
                        variant="contained"
                        onClick={this.savePoints}
                      >
                        Save
                      </Button>
                      <br />
                      {/* <Button>Reset</Button> */}
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </SectionPaper>
          </Grid>
        </div>
      </div>
    );
  }
}

Save.propTypes = {
  PointsData: PropTypes.object,
  save: PropTypes.func,
  updatePoints: PropTypes.func,
  getPoints: PropTypes.func,
  messageShow: PropTypes.func,
  classes: PropTypes.object,
};

// Component State
function createOrEditState(state) {
  return {
    PointsDataParent: state.PointsDataParent,
  };
}

export default connect(createOrEditState, {
  getPoints,
  messageShow,
  updatePoints,
})(withStyles(styles)(Save));
