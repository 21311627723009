// Imports
import axios from 'axios'

// App Imports
import { API_URL } from '../../../../setup/config/env'

// Actions


// Get detail
export function detail({ page, userId, filter }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'userDetail',
      params: { page, userId, filter }
    })
  }
}