import axios from 'axios';
import { API_URL } from '../../../../setup/config/env';

export const updateProductPrice = async (productName, newPrice) => {
  try {
    const response = await axios.patch(`${API_URL}/api/analytics/update-price`,{productName,newPrice},
      {
        headers: {"Content-Type": "application/json"}
      }
    )
    return response.data;
  } catch (error) {
    // console.log(error.response.data.error);
    return {error: error.response?.data?.error || "An unknown error occurred"};
  }
};