// Imports
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// UI Import
import Grid from '@material-ui/core/Grid'
import { Paper, Select, MenuItem } from "@material-ui/core";
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Anchor from '@material-ui/core/Link'
import IconButton from '@material-ui/core/IconButton'
import CheckIcon from '@mui/icons-material/Check';
import IconCheckCircle from '@material-ui/icons/CheckCircle'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'

// App Imports
import params from '../../../setup/config/params'
import { nullToEmptyString } from '../../../setup/helpers'
import { messageShow } from '../../common/api/actions'
import { signup } from '../api/actions/mutation'
import AuthCheck from '../../auth/AuthCheck'
import routes from '../api/routes'

// Component
class Signup extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      isSubmitting: false,
      isSubmitted: false,

      name: '',
      email: '',
      mobile: '',
      password: '',
      role: ''
    }
  }

  signup = async event => {
    event.preventDefault()

    const { signup, messageShow } = this.props

    this.isSubmittingToggle(true)

    try {
      const { name, email, mobile, password, role } = this.state

      const { data } = await signup({ name, email, mobile, password, role })

      if(data.success) {
        this.setState({
          isSubmitted: true
        })
      } else {
        messageShow(data.message)
      }
    } catch (error) {
      messageShow('Some error occurred. Please try again.')
    } finally {
      this.isSubmittingToggle(false)
    }
  }

  isSubmittingToggle = isSubmitting => {
    this.setState({
      isSubmitting
    })
  }

  onType = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  render() {
    const { classes } = this.props
    const { isSubmitted, isSubmitting, name, email, mobile, password, role } = this.state


    return (
      <div className={classes.root}>
        <Paper className={classes.container}>
          <Typography
            variant={'subtitle1'}
            color={'inherit'}
            className={classes.heading}
          >
            {role === 'admin' ? `Become an Admin on ${params.site.name}` : role ==='worker' ? `Join as a Worker on ${params.site.name}` : role==='vendor'?`Sell on ${params.site.name} - Signup as Vendor`: `Signup on ${params.site.name}`}
          </Typography>

          {
            isSubmitted
              ? <div>
                  <IconCheckCircle color="primary" fontSize="large" />

                <Typography
                  variant={'subtitle1'}
                  color={'inherit'}
                  className={classes.heading}
                >
                  Your application is submitted successfully. We'll contact you on your mobile number shortly.
                </Typography>
                </div>
              : <form onSubmit={this.signup}>
                  {/* Input - name */}
                  <Grid item xs={12}>
                    <TextField
                      name={'name'}
                      value={nullToEmptyString(name)}
                      onChange={this.onType}
                      label={'Name'}
                      placeholder={'Enter name'}
                      required={true}
                      margin={'dense'}
                      autoComplete={'off'}
                      fullWidth
                      autoFocus
                    />
                  </Grid>

                  {/* Input - mobile */}
                  <Grid item xs={12}>
                    <TextField
                      name={'mobile'}
                      value={nullToEmptyString(mobile)}
                      onChange={this.onType}
                      label={'Mobile'}
                      placeholder={'Enter 10 digit mobile number'}
                      required={true}
                      margin={'dense'}
                      autoComplete={'off'}
                      fullWidth
                      inputProps={{ maxLength: 10 }}
                    />
                  </Grid>

                  {/* Input - email */}
                  <Grid item xs={12}>
                    <TextField
                      name={'email'}
                      type={'email'}
                      value={nullToEmptyString(email)}
                      onChange={this.onType}
                      label={'Email'}
                      placeholder={'Enter email'}
                      required={true}
                      margin={'dense'}
                      autoComplete={'off'}
                      fullWidth
                    />
                  </Grid>

                  {/* Input - password */}
                  <Grid item xs={12}>
                    <TextField
                      name={'password'}
                      type={'password'}
                      value={nullToEmptyString(password)}
                      onChange={this.onType}
                      label={'Password'}
                      placeholder={'Enter password'}
                      required={true}
                      margin={'dense'}
                      autoComplete={'off'}
                      fullWidth
                    />
                  </Grid>
                  {/* Role */}
                  <Grid item xs={12} style={{ textAlign: 'left', width: '100%', marginTop: '20px' }}> 
                    <Select
                      name={'role'}
                      value={role}
                      onChange={this.onType}
                      displayEmpty
                      style={{width:"100%"}}
                      required
                    >
                      <MenuItem value="" disabled style={{ color: 'text.disabled' }}>Select role*</MenuItem>
                      <MenuItem value={'admin'}>Admin</MenuItem>
                      <MenuItem value={'worker'}>Worker</MenuItem>
                    </Select>
                  </Grid>

                  {/* Button -  Save */}
                  <Grid item xs={12} className={classes.buttonsContainer}>
                    <IconButton
                      type={'submit'}
                      aria-label={'Save'}
                      color={'primary'}
                      disabled={isSubmitting}
                    >
                      <CheckIcon/>
                    </IconButton>
                  </Grid>
                </form>
          }
        </Paper>

        <Link to={routes.userLogin.path}>
          <Anchor component="span">Already have an account - Login</Anchor>
        </Link>

        {/* Auth Check */}
        <AuthCheck />
      </div>
    )
  }
}

// Component Properties
Signup.propTypes = {
  auth: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  signup: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired
}

// Component State
function loginState(state) {
  return {
    auth: state.auth
  }
}

export default connect(loginState, { signup, messageShow })(withStyles(styles)(Signup))
