// Imports
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// UI Imports
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  FormGroup,
  Radio,
  Divider,
  Switch,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import Grid from "@material-ui/core/Grid/Grid";
import TextField from "@mui/material/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

// Accordion
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
// import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@material-ui/core/Typography";

// Tabs
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

// ---- Datepicket UI Library imports
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import dayjsUTC from "dayjs/plugin/utc";
import dayjsTz from "dayjs/plugin/timezone";
import customFormat from "dayjs/plugin/customParseFormat";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo/DemoContainer";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

// App Imports
import { nullToEmptyString } from "../../../setup/helpers";
import { messageShow } from "../../common/api/actions";
import SectionPaper from "../../common/SectionPaper";
import Loading from "../../common/Loading";
import {
  detail,
  getAllCountryCodes,
  getAllCurrencies,
} from "../api/actions/query";
import { getAllPincodes } from "../../pincode/api/actions/query";
import { save, updateSettings } from "../api/actions/mutation";

import { encrption_secrey_key } from "../../../setup/config/env";
import timeZones from "../../../setup/config/timezone.json";
import { upload } from "../../common/api/actions";
import ImageComp from "../../common/Image";
import { getImageSource } from "../api/routes";
import moment from "moment";
import { Container, Row, Col, Tabs } from "react-bootstrap";

import CryptoJS from "crypto-js";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";

// Component
class CreateOrUpdate extends Component {
  constructor(props) {
    super(props);
    dayjs.extend(customFormat);
    this.secretPass = encrption_secrey_key;
    this.settings = {
      _id: "",
      vendorId: "",
      phoneLine1: "",
      phoneLine2: "",
      paymentConfiguration: [
        {
          name: "sandbox",
          config: {
            type: "sandbox",
            accessKey: "",
            accessToken: "",
            locationId: "",
            isActive: false,
          },
        },
        {
          name: "production",
          config: {
            type: "production",
            accessKey: "",
            accessToken: "",
            locationId: "",
            isActive: false,
          },
        },
      ],
      workingHours: [
        {
          day: "SUN",
          isHoliday: false,
          bStartTime: null,
          bEndTime: null,
          lStartTime: null,
          lEndTime: null,
        },
        {
          day: "MON",
          isHoliday: false,
          bStartTime: null,
          bEndTime: null,
          lStartTime: null,
          lEndTime: null,
        },
        {
          day: "TUE",
          isHoliday: false,
          bStartTime: null,
          bEndTime: null,
          lStartTime: null,
          lEndTime: null,
        },
        {
          day: "WED",
          isHoliday: false,
          bStartTime: null,
          bEndTime: null,
          lStartTime: null,
          lEndTime: null,
        },
        {
          day: "THU",
          isHoliday: false,
          bStartTime: null,
          bEndTime: null,
          lStartTime: null,
          lEndTime: null,
        },
        {
          day: "FRI",
          isHoliday: false,
          bStartTime: null,
          bEndTime: null,
          lStartTime: null,
          lEndTime: null,
        },
        {
          day: "SAT",
          isHoliday: false,
          bStartTime: null,
          bEndTime: null,
          lStartTime: null,
          lEndTime: null,
        },
      ],
      timeZone: "",
      taxAmount: "",
      onlinePricingPercentage: "",
      payAtStore: false,
      payOnline: false,
      cashOnDelivery: false,
      image: "",
      logo: "",
      countryCode: "",
      currency: "",
      charges: [],
      aboutUs: "",
      terms: "",
      privacyPolicy: "",
      faq: "",
      feedback: "",
      email: "",
      iosAppUrl:"",
      androidAppUrl:"",
      facebook:"",
      website:"",
      instagram:"",
      facebook_app_id:"",
      facebook_secret:"",
      facebook_access_token:"",
      facebook_page_id:"",
      isPickupAtStore:false,
      isHomeDelivery:false,
      infomsg1:"",
      infomsg2:"",
      infomsg3:"",
      isTaxAvailable:false,
      pickupAtStore: {
        paymentMethods: [],
      },

      homeDelivery: {
        deliveryPincodes: [],
        deliveryCharge: "",
        deliveryType: "",
        minimumAmount: "",
        paymentMethods: [],
      },
      uberSettings: {
        uberCustomerId: "",
        uberClientId: "",
        uberClientSecret: "",
      },
      isUberDeliveryEnabled: false,
    };

    this.state = {
      isLoading: false,
      isSubmitting: false,
      settings: this.settings,
      newCharge: {
        label: "",
        amount: "",
      },
      expanded: "panel1",
      tabValue: "1",
      gVal: null,
      countryCodes: [],
      currencies: [],
      pincodes: [],
      homeDeliveryPaymentMode: [],
      pickupPaymentMode: [],
      isUploadingFile: false,
      isUploadingFile2: false,
      showPassword: false,
    };
  }

  handleAccordion = (panel) => {
    const value = this.state.expanded === panel ? false : panel;
    this.setState({ expanded: value });
  };

  handleTabs = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  async componentDidMount() {
    this.refresh();
    this.fetchCountryCodes();
    this.fetchCurrencies();
    this.fetchPincodes();
  }

  fetchCountryCodes = async () => {
    const { getAllCountryCodes } = this.props;
    try {
      const { data } = await getAllCountryCodes();
      if (data.success) {
        this.setState({ countryCodes: data.data });
      }
    } catch (error) {
      console.error("Error fetching country codes:", error);
    }
  };

  fetchCurrencies = async () => {
    const { getAllCurrencies } = this.props;
    try {
      const { data } = await getAllCurrencies();
      if (data.success) {
        this.setState({ currencies: data.data });
      }
    } catch (error) {
      console.error("Error fetching currencies:", error);
    }
  };
  fetchPincodes = async () => {
    const { getAllPincodes } = this.props;
    try {
      const { data } = await getAllPincodes();
      if (data.success) {
        this.setState({ pincodes: data.data });
      }
    } catch (error) {
      console.error("Error fetching pincodes:", error);
    }
  };

  refresh = async () => {
    const { detail, messageShow } = this.props;

    this.isLoadingToggle(true);

    try {
      const {
        match: {
          params: { vendorId },
        },
      } = this.props;
      this.setState({
        settings: { vendorId },
      });
      const { data } = await detail({ vendorId });
      if (data.success && data.data) {
        if (data.data.paymentConfiguration.length > 0) {
          for (let paymentConfig of data.data.paymentConfiguration) {
            for (let config of paymentConfig.config) {
              try {
                if (config.accessToken) {
                  config.accessToken = await this.decryptData(
                    config.accessToken
                  );
                }
                if (config.accessKey) {
                  config.accessKey = await this.decryptData(config.accessKey);
                }
                if (config.locationId) {
                  config.locationId = await this.decryptData(config.locationId);
                }
              } catch (decryptionError) {
                messageShow(
                  "Failed to decrypt payment configuration. Please try again."
                );
              }
            }
          }
        }
        if (data.data.uberSettings) {
          try {
            if (data.data.uberSettings.uberClientId) {
              data.data.uberSettings.uberClientId = await this.decryptData(
                data.data.uberSettings.uberClientId
              );
            }
            if (data.data.uberSettings.uberClientSecret) {
              data.data.uberSettings.uberClientSecret = await this.decryptData(
                data.data.uberSettings.uberClientSecret
              );
            }
            if (data.data.uberSettings.uberCustomerId) {
              data.data.uberSettings.uberCustomerId = await this.decryptData(
                data.data.uberSettings.uberCustomerId
              );
            }
            console.log(data.data.uberSettings);
          } catch (decryptionError) {
            messageShow(
              "Failed to decrypt uber configuration. Please try again."
            );
          }
        }
        if (data.data?.workingHours?.length > 0) {
          const workHrs = data.data.workingHours?.map((eWorkHrs) => {
            for (let key in eWorkHrs) {
              if (eWorkHrs[key] && key.includes("Time")) {
                eWorkHrs[key] = dayjs(eWorkHrs[key].toString(), "HH:mm");
              }
            }
            return eWorkHrs;
          });
          data.data.workingHours = workHrs;
        } else {
          data.data.workingHours = this.settings.workingHours;
        }
        data.data.timeZone =
          data.data.timeZone !== undefined ? data.data.timeZone : "";

        this.setState({
          settings: data.data,
        });
      }
    } catch (error) {
      messageShow("Some error occurred. Please try again.");
    } finally {
      this.isLoadingToggle(false);
    }
  };

  encryptData = async (value) => {
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(value),
      this.secretPass
    ).toString();
    return data;
  };

  decryptData = async (value) => {
    const bytes = CryptoJS.AES.decrypt(value, this.secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return data;
  };

  // onSave method
  onSave = async (event) => {
    event.preventDefault();

    const { save, messageShow } = this.props;

    const { settings } = this.state;

    this.isSubmittingToggle(true);

    try {
      // Encrypt sensitive data

      for (let i = 0; i < settings.paymentConfiguration.length; i++) {
        let config = settings.paymentConfiguration[i].config;
        if (config.accessKey) {
          config.accessKey = await this.encryptData(config.accessKey);
        }
        if (config.accessToken) {
          config.accessToken = await this.encryptData(config.accessToken);
        }
        if (config.locationId) {
          config.locationId = await this.encryptData(config.locationId);
        }
      }
      const { data } = await save(settings);
      this.isSubmittingToggle(false);
      messageShow(data.message);
    } catch (error) {
      this.isSubmittingToggle(false);

      messageShow("Some error occurred. Please try again.");
    }
  };
  onUpload = async (event) => {
    const { upload, messageShow } = this.props;

    messageShow("Uploading file, please wait...");

    this.isUploadingFileToggle(true);

    let file = new FormData();
    file.append("type", "settings");
    file.append("name", "logo");
    file.append("file", event.target.files[0]);

    const fileReader = new FileReader();
    fileReader.onload = () => {
      const fileResult = fileReader.result;
      let dataAsImg = new Image();
      dataAsImg.src = fileResult;
      this.setState({ previewImage: dataAsImg.src });
    };
    fileReader.readAsDataURL(event.target.files[0]);

    // Upload image
    try {
      const { data } = await upload(file);

      if (data.success) {
        this.props.messageShow("File uploaded successfully.");

        const { settings } = this.state;

        setTimeout(() => {
          settings.image = data.file;
          this.setState({
            settings,
          });
        }, 1000);
      } else {
        messageShow("There was some error. Please try again.");
      }
    } catch (error) {
      messageShow("There was some error. Please try again.");
    } finally {
      this.isUploadingFileToggle(false);
    }
  };
  onUpload2 = async (event) => {
    const { upload, messageShow } = this.props;

    messageShow("Uploading file, please wait...");

    this.isUploadingFileToggle2(true);

    let file = new FormData();
    file.append("type", "settings");
    file.append("name", "default");
    file.append("file", event.target.files[0]);

    const fileReader = new FileReader();
    fileReader.onload = () => {
      const fileResult = fileReader.result;
      let dataAsImg = new Image();
      dataAsImg.src = fileResult;
      this.setState({ previewImage2: dataAsImg.src });
    };
    fileReader.readAsDataURL(event.target.files[0]);

    // Upload image
    try {
      const { data } = await upload(file);

      if (data.success) {
        this.props.messageShow("File uploaded successfully.");

        const { settings } = this.state;

        setTimeout(() => {
          settings.logo = data.file;
          this.setState({
            settings,
          });
        }, 1000);
      } else {
        messageShow("failed to upload image");
      }
    } catch (error) {
      messageShow("Error on uploading logo");
    } finally {
      this.isUploadingFileToggle2(false);
    }
  };

  isUploadingFileToggle = (isUploadingFile) => {
    this.setState({
      isUploadingFile,
    });
  };

  isUploadingFileToggle2 = (isUploadingFile2) => {
    this.setState({
      isUploadingFile2,
    });
  };

  isLoadingToggle = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  isSubmittingToggle = (isSubmitting) => {
    this.setState({
      isSubmitting,
    });
  };

  onChange = ({ target: { name, value } }) => {
    const { settings } = this.state;
    settings[name] = value;
    this.setState({
      settings,
    });
  };

  onChangesandbox = (event) => {
    const { name, value } = event.target;
    const { settings } = this.state;
    this.setState({
      settings: {
        ...settings,
        sandbox: {
          ...settings.sandbox,
          [name]: value,
        },
      },
    });
  };

  onChangeproduction = (event) => {
    const { name, value } = event.target;
    const { settings } = this.state;
    this.setState({
      settings: {
        ...settings,
        production: {
          ...settings.production,
          [name]: value,
        },
      },
    });
  };

  onChangePaymentMethod = (value) => {
    const { settings } = this.state;
    const updatedPaymentConfigurations = settings.paymentConfiguration.map(
      (configGroup) => ({
        ...configGroup,
        config: configGroup.config.map((config) => ({
          ...config,
          isActive: config.type === value,
        })),
      })
    );

    this.setState({
      settings: {
        ...settings,
        paymentConfiguration: updatedPaymentConfigurations,
      },
    });
  };

  onPaymentConfigChange = (e, index, configIndex) => {
    const { name, value } = e.target;
    const { settings } = this.state;
    const newSettings = JSON.parse(JSON.stringify(settings));
    newSettings.paymentConfiguration[index].config[configIndex][
      name.split("_")[1]
    ] = value;
    this.setState({
      settings: newSettings,
    });
  };

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  onChangeHomeDelivery = (event) => {
    const { name, value } = event.target;

    if (/[a-zA-Z]/.test(value)) {
      alert("Please Enter Only Number !!");
      return;
    }
    this.setState({
      settings: {
        ...this.state.settings,
        homeDelivery: {
          ...this.state.settings.homeDelivery,
          [name]: value,
        },
      },
    });
  };

  onChangePickup = (event) => {
    const { settings } = this.state;
    const { value } = event.target;
    this.setState({
      settings: {
        ...settings,
        pickupAtStore: {
          ...settings.pickupAtStore,
          paymentMethods: value,
        },
      },
    });
  };

  onChangeSelfHomeDelivery = (event) => {
    const { settings } = this.state;
    const { value } = event.target;
    this.setState({
      settings: {
        ...settings,
        homeDelivery: {
          ...settings.homeDelivery,
          paymentMethods: value,
        },
      },
    });
  };

  onChangePincode = (e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      settings: {
        ...prevState.settings,
        homeDelivery: {
          ...prevState.settings.homeDelivery,
          deliveryPincodes: value !== null ? value : [],
        },
      },
    }));
  };

  onChangedeliverytype = (e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      settings: {
        ...prevState.settings,
        homeDelivery: {
          ...prevState.settings.homeDelivery,
          deliveryType: value,
        },
      },
    }));
  };

  onChangeisPickupAtStore = (event) => {
    const { checked } = event.target;
    this.setState((prevState) => {
      let updatedSettings = { ...prevState.settings };
      if (!checked) {
        // Clear data related to Pickup At Store when unchecked
        updatedSettings.isPickupAtStore = false;
        updatedSettings.pickupAtStore = {
          paymentMethod: [],
          // Add other fields that need to be cleared
        };
      } else {
        updatedSettings.isPickupAtStore = true;
      }
      return { settings: updatedSettings };
    });
  };
  onChangeisHomeDeliveryy = (event) => {
    const { checked } = event.target;
    this.setState((prevState) => {
      let updatedSettings = { ...prevState.settings };
      if (!checked) {
        // Clear data related to Pickup At Store when unchecked
        updatedSettings.isHomeDelivery = false;
        updatedSettings.homeDelivery = {
          deliveryCharge: "",
          deliveryType: "",
          minimumAmount: "",
          deliveryPincodes: [],
          paymentMethod: [],
        };
      } else {
        updatedSettings.isHomeDelivery = true;
      }
      return { settings: updatedSettings };
    });
  };
  onChangeCountryCode = (event) => {
    const { settings } = this.state;
    settings.countryCode = event.target.value;
    this.setState({
      settings,
    });
  };

  onChangeCurrency = (event) => {
    const { settings } = this.state;
    settings.currency = event.target.value;
    this.setState({
      settings,
    });
  };

  onChangeTime = (newVal, mode, day, isHoliday) => {
    const workingHours = [...this.state.settings.workingHours].map((mWhrs) => {
      if (mWhrs.day === day) {
        mWhrs[mode] = newVal ? dayjs(newVal).format() : null;
        if (isHoliday !== undefined) mWhrs.isHoliday = isHoliday;
      }
      return mWhrs;
    });
    const settings = { ...this.state.settings, workingHours: workingHours };
    this.setState({ settings: settings });
  };

  resetTime = (day, resetSlot) => {
    // 'morn', 'noon', 'eve'
    const workingHours = [...this.state.settings.workingHours].map((mWhrs) => {
      if (mWhrs.day === day) {
        if (resetSlot === "morn") {
          mWhrs.bStartTime = null;
          mWhrs.bEndTime = null;
        } else {
          mWhrs.lStartTime = null;
          mWhrs.lEndTime = null;
        }
      }
      return mWhrs;
    });
    const settings = { ...this.state.settings, workingHours: workingHours };
    this.setState({ settings: settings });
  };

  appConfigChange = (type, value) => {
    const key = type === "tax" ? "taxAmount" : "timeZone";
    const settings = { ...this.state.settings, [key]: value };
    this.setState({ settings: settings });
  };

  taxChargeChange = (key, value) => {
    const settings = { ...this.state.settings, [key]: value };
    if (parseFloat(value) >0) {
        settings.isTaxAvailable = true;
    } else {
        settings.isTaxAvailable = false;
    }
    this.setState({ settings: settings });
  };

  newChargeChange = (key, value) => {
    const newCharge = { ...this.state.newCharge, [key]: value };
    this.setState({ newCharge: newCharge });
  };

  addNewCharge = () => {
    const { settings, newCharge } = this.state;
    const charges = settings?.charges || [];
    charges.push(newCharge);
    this.setState({ newCharge: { label: "", amount: "" } });
    const updatedSettings = { ...settings, charges: charges };
    this.setState({ settings: updatedSettings });
  };

  removeCharge = (index) => {
    const { settings } = this.state;
    const updatedCharges = [...settings.charges];
    updatedCharges.splice(index, 1);
    const updatedSettings = { ...settings, charges: updatedCharges };
    this.setState({ settings: updatedSettings });
  };

  // Uber Settings change
  uberSettingsChange = (key, value) => {
    const settings = {
      ...this.state.settings,
      uberSettings: { ...this.state.settings.uberSettings, [key]: value },
    };
    this.setState({ settings: settings });
  };

  // Save App Configuration
  saveAppConfig = () => {
    const {
      timeZone,
      countryCode,
      aboutUs,
      terms,
      privacyPolicy,
      faq,
      feedback,
      email,
      iosAppUrl,
      androidAppUrl,
      onlinePricingPercentage,
      image,
      logo,
    } = this.state.settings;
    this.modifySettings({
      timeZone,
      countryCode,
      aboutUs,
      terms,
      privacyPolicy,
      faq,
      feedback,
      email,
      iosAppUrl,
      androidAppUrl,
      onlinePricingPercentage,
      image,
      logo,
    });
  };
  saveDeliverySetup = () => {
    const { settings } = this.state;
    if (!(settings.isPickupAtStore || settings.isHomeDelivery)) {
      alert("Please select atleast 1 delivery type  !!");
      return;
    }
    const {
      isPickupAtStore,
      isHomeDelivery,
      infomsg1,
      infomsg2,
      infomsg3,
      pickupAtStore,
      homeDelivery,
    } = this.state.settings;
    this.modifySettings({
      isPickupAtStore,
      isHomeDelivery,
      infomsg1,
      infomsg2,
      infomsg3,
      pickupAtStore,
      homeDelivery,
    });
  };

  saveTaxCharges = () => {
    const { taxAmount, currency, charges,isTaxAvailable } = this.state.settings;
    this.modifySettings({ taxAmount, currency, charges,isTaxAvailable });
  };

  // Save working Hours
  saveAppWorkingHours = () => {
    let workingHours = JSON.parse(
      JSON.stringify(this.state.settings.workingHours)
    );
    this.isSubmittingToggle(true);
    workingHours.map((eWrkhrs) => {
      if (!eWrkhrs.isHoliday) {
        for (let key in eWrkhrs) {
          if (eWrkhrs[key] && key.includes("Time")) {
            eWrkhrs[key] = dayjs(eWrkhrs[key].toString()).format("HH:mm");
          }
        }
        return eWrkhrs;
      }
    });
    this.modifySettings({ workingHours: workingHours });
  };

  // Save payment condifuration
  savePaymentConfig = async () => {
    const { phoneLine1, phoneLine2, paymentConfiguration } =
      this.state.settings;
    const newPaymentConfiguration = JSON.parse(
      JSON.stringify(paymentConfiguration)
    );

    // Process payment configuration to handle sensitive data
    const sensitiveFields = ["accessKey", "accessToken", "locationId"];
    for (let i = 0; i < newPaymentConfiguration.length; i++) {
      for (let j = 0; j < newPaymentConfiguration[i].config.length; j++) {
        const config = newPaymentConfiguration[i].config[j];
        for (const key in config) {
          if (sensitiveFields.includes(key) && config[key]) {
            const encryptedValue = await this.encryptData(config[key]);
            newPaymentConfiguration[i].config[j][key] = encryptedValue;
          }
        }
      }
    }
    // Call modifySettings with the updated payment configuration
    this.modifySettings({
      phoneLine1,
      phoneLine2,
      paymentConfiguration: newPaymentConfiguration,
    });
  };

  // Save Uber Settings
  saveUberSettings = async () => {
    const { uberSettings } = this.state.settings;
    const newUberSettings = JSON.parse(JSON.stringify(uberSettings));
    for (const key in newUberSettings) {
      if (newUberSettings[key]) {
        const encryptedValue = await this.encryptData(newUberSettings[key]);
        newUberSettings[key] = encryptedValue;
      }
    }
    this.modifySettings({ uberSettings: newUberSettings });
  };

  //save social handles
  saveSocialHandles = () => {
    const { settings } = this.state;
    this.modifySettings({
      facebook: settings.facebook,
      website: settings.website,
      instagram: settings.instagram,
      facebook_app_id: settings.facebook_app_id,
      facebook_secret: settings.facebook_secret,
      facebook_access_token: settings.facebook_access_token,
      facebook_page_id: settings.facebook_page_id,
    });
  };

  // Update Specific data only
  modifySettings = async (requestObject) => {
    // requestObject as Object
    const { updateSettings, messageShow } = this.props;
    const { vendorId } = this.state.settings;
    const { data } = await updateSettings({ vendorId, requestObject });
    this.isSubmittingToggle(false);
    messageShow(data.message);
  };

  onCloseEvent = (mode, index) => {
    let { workingHours } = this.state.settings;
    if (mode === "bStartTime") {
      workingHours[index].bEndTime = dayjs(
        workingHours[index].bStartTime
      ).format();
    }
    if (mode === "lStartTime") {
      workingHours[index].lEndTime = dayjs(
        workingHours[index].lStartTime
      ).format();
    }
    const settings = { ...this.state.settings, workingHours: workingHours };
    this.setState({ settings: settings });
  };

  render() {
    const { classes } = this.props;
    const {
      isLoading,
      isSubmitting,
      settings,
      newCharge,
      isUploadingFile,
      isUploadingFile2,
      showPassword,
    } = this.state;

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" classes={classes.grow}>
            Settings
          </Typography>
        </Toolbar>

        <div style={{ padding: "18px" }}>
          <Grid item xs={12}>
            <Accordion
              expanded={this.state.expanded === "panel1"}
              onChange={(event) => this.handleAccordion("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className="w-1/3">
                  <b>App Configuration</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <form>
                  {/* First Row */}
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">
                          Timezone
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={settings.timeZone}
                          name="timeZone"
                          label="- Choose Timezone -"
                          variant="standard"
                          onChange={(event) =>
                            this.appConfigChange("timeZone", event.target.value)
                          }
                        >
                          <MenuItem selected disabled>
                            - Choose Timezone -
                          </MenuItem>
                          {timeZones?.map((eTime, eIndex) => (
                            <MenuItem key={eIndex} value={eTime?.zone}>
                              {eTime.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">
                          Country Code
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={settings.countryCode}
                          name="countryCode"
                          label="- Choose Country Code -"
                          variant="standard"
                          onChange={this.onChangeCountryCode}
                        >
                          <MenuItem selected disabled>
                            - Choose Country Code -
                          </MenuItem>
                          {this.state.countryCodes.map((country) => (
                            <MenuItem key={country._id} value={country._id}>
                              {`${country.code} ${country.name}`}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* Second Row */}
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        variant="standard"
                        name={"aboutUs"}
                        type={"url"}
                        value={nullToEmptyString(settings.aboutUs)}
                        onChange={this.onChange}
                        label={"About Us URL"}
                        placeholder={"Enter About Us URL"}
                        margin={"dense"}
                        autoComplete={"off"}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="standard"
                        name={"terms"}
                        type={"url"}
                        value={nullToEmptyString(settings.terms)}
                        onChange={this.onChange}
                        label={"Terms URL"}
                        placeholder={"Enter Terms URL"}
                        margin={"dense"}
                        autoComplete={"off"}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  {/* Third Row */}
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        variant="standard"
                        name={"privacyPolicy"}
                        type={"url"}
                        value={nullToEmptyString(settings.privacyPolicy)}
                        onChange={this.onChange}
                        label={"Privacy Policy URL"}
                        placeholder={"Enter Privacy Policy URL"}
                        margin={"dense"}
                        autoComplete={"off"}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="standard"
                        name={"faq"}
                        value={nullToEmptyString(settings.faq)}
                        onChange={this.onChange}
                        label={"Faq URL"}
                        placeholder={"Enter Faq URL"}
                        margin={"dense"}
                        autoComplete={"off"}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  {/* fourth Row */}
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        variant="standard"
                        name={"feedback"}
                        type={"url"}
                        value={nullToEmptyString(settings.feedback)}
                        onChange={this.onChange}
                        label={"Feedback URL"}
                        placeholder={"Enter Feedback URL"}
                        margin={"dense"}
                        autoComplete={"off"}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="standard"
                        name={"email"}
                        value={nullToEmptyString(settings.email)}
                        onChange={this.onChange}
                        label={"Email"}
                        placeholder={"Enter Email"}
                        margin={"dense"}
                        autoComplete={"off"}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <b>Mobile App URL</b>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        variant="standard"
                        name={"iosAppUrl"}
                        type={"url"}
                        value={nullToEmptyString(settings.iosAppUrl)}
                        onChange={this.onChange}
                        label={"Ios App URL"}
                        placeholder={"Enter Ios App URL"}
                        margin={"dense"}
                        autoComplete={"off"}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="standard"
                        name={"androidAppUrl"}
                        type={"url"}
                        value={nullToEmptyString(settings.androidAppUrl)}
                        onChange={this.onChange}
                        label={"Android App URL"}
                        placeholder={"Enter Android App URL"}
                        margin={"dense"}
                        autoComplete={"off"}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        variant="standard"
                        name={"onlinePricingPercentage"}
                        value={nullToEmptyString(
                          settings.onlinePricingPercentage
                        )}
                        onChange={this.onChange}
                        label={"Online Price Percentage"}
                        placeholder={"Enter Online Price Percentage"}
                        margin={"dense"}
                        autoComplete={"off"}
                        fullWidth
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <b>Company Logo</b>
                      <Grid
                        container
                        spacing={10}
                        className={classes.buttonUpload}
                        alignItems="center"
                      >
                        <Grid item md={6}>
                          {!this.state.previewImage ? (
                            <ImageComp
                              src={getImageSource(`${settings.image}`)}
                              defaultSrc={getImageSource()}
                              size={"100%"}
                            />
                          ) : (
                            <img
                              height="auto"
                              width="100%"
                              src={this.state.previewImage}
                              alt="preview_banner"
                            ></img>
                          )}
                        </Grid>
                        <Grid item md={6}>
                          <input
                            accept={"image/png,image/jpeg"}
                            style={{ display: "none" }}
                            id={"company-contained-button-file"}
                            type={"file"}
                            onChange={this.onUpload}
                          />

                          <label htmlFor={"company-contained-button-file"}>
                            <Button
                              variant={"outlined"}
                              component={"span"}
                              type={"file"}
                              fullWidth
                              disabled={isUploadingFile}
                            >
                              Upload Image
                            </Button>
                          </label>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <b>Default Image</b>
                      <Grid
                        container
                        spacing={10}
                        className={classes.buttonUpload}
                        alignItems="center"
                      >
                        <Grid item md={6}>
                          {!this.state.previewImage2 ? (
                            <ImageComp
                              src={getImageSource(`${settings.logo}`)}
                              defaultSrc={getImageSource()}
                              size={"100%"}
                            />
                          ) : (
                            <img
                              height="auto"
                              width="100%"
                              src={this.state.previewImage2}
                              alt="preview_banner"
                            ></img>
                          )}
                        </Grid>
                        <Grid item md={6}>
                          <input
                            accept={"image/png,image/jpeg"}
                            style={{ display: "none" }}
                            id={"default-contained-button-file"}
                            type={"file"}
                            onChange={this.onUpload2}
                          />

                          <label htmlFor={"default-contained-button-file"}>
                            <Button
                              variant={"outlined"}
                              component={"span"}
                              type={"file"}
                              fullWidth
                              disabled={isUploadingFile2}
                            >
                              Upload logo
                            </Button>
                          </label>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <div className="col-12 py-2 text-end">
                    <Grid
                      container
                      justifyContent="center"
                      className={classes.buttonsContainer}
                    >
                      <Button
                        aria-label={"Save"}
                        size="medium"
                        color={"primary"}
                        disabled={isSubmitting}
                        variant="contained"
                        onClick={this.saveAppConfig}
                      >
                        Save
                      </Button>
                    </Grid>
                  </div>
                </form>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={this.state.expanded === "panel4"}
              onChange={(event) => this.handleAccordion("panel4")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  <b>Taxes and Charges</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid container item spacing={2} xs={12}>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Currency
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={settings.currency}
                          name="currency"
                          label="- Choose Currency -"
                          variant="standard"
                          onChange={this.onChangeCurrency}
                        >
                          <MenuItem selected disabled>
                            - Choose Currency -
                          </MenuItem>
                          {this.state.currencies.map((currency) => (
                            <MenuItem key={currency._id} value={currency._id}>
                              {currency.symbol} {currency.code}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="standard-basic"
                        label="Tax Percentage"
                        placeholder="Enter Tax Percentage"
                        variant="standard"
                        name="taxAmount"
                        value={nullToEmptyString(settings.taxAmount)}
                        onChange={(event) =>
                          this.taxChargeChange("taxAmount", event.target.value)
                        }
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Charges :</Typography>
                  </Grid>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        label="Charge Label"
                        value={nullToEmptyString(newCharge.label)}
                        onChange={(event) =>
                          this.newChargeChange("label", event.target.value)
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Charge Amount"
                        value={nullToEmptyString(newCharge.amount)}
                        onChange={(event) =>
                          this.newChargeChange("amount", event.target.value)
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.addNewCharge}
                      >
                        Add Charge
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Existing Charges :</Typography>
                  </Grid>
                  <Grid container item xs={12}>
                    {settings.charges?.length > 0 ? (
                      settings.charges.map((charge, index) => (
                        <Grid
                          item
                          container
                          xs={12}
                          spacing={2}
                          key={index}
                          style={{ paddingBottom: "10px" }}
                        >
                          <Grid item xs={4}>
                            <TextField
                              label="Charge Label"
                              value={charge.label}
                              onChange={(e) =>
                                this.onChargeChange(
                                  index,
                                  "label",
                                  e.target.value
                                )
                              }
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              label="Charge Amount"
                              value={charge.amount}
                              onChange={(e) =>
                                this.onChargeChange(
                                  index,
                                  "amount",
                                  e.target.value
                                )
                              }
                              fullWidth
                            />
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => this.removeCharge(index)}
                            >
                              Remove Charge
                            </Button>
                          </Grid>
                        </Grid>
                      ))
                    ) : (
                      <Grid item xs={12}>
                        <Typography>No Existing Charges</Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <div className="col-12 py-2 text-end">
                  <Grid
                    container
                    justifyContent="center"
                    className={classes.buttonsContainer}
                  >
                    <Button
                      aria-label={"Save"}
                      size="medium"
                      color={"primary"}
                      disabled={isSubmitting}
                      variant="contained"
                      onClick={this.saveTaxCharges}
                    >
                      Save
                    </Button>
                  </Grid>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={this.state.expanded === "panel2"}
              onChange={(event) => this.handleAccordion("panel2")}
              disabled={!settings.timeZone}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  <b>Working Hours</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {!this.state.tabValue ? (
                  <div>Loading.... {this.state.tabValue}</div>
                ) : (
                  <>
                    <TabContext value={this.state.tabValue}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                          onChange={this.handleTabs}
                          aria-label="lab API tabs example"
                        >
                          <Tab label="Sunday" value="1" />
                          <Tab label="Monday" value="2" />
                          <Tab label="Tuesday" value="3" />
                          <Tab label="Wednesday" value="4" />
                          <Tab label="Thursday" value="5" />
                          <Tab label="Friday" value="6" />
                          <Tab label="Saturday" value="7" />
                        </TabList>
                      </Box>
                      {settings.workingHours?.map((eItem, eIndex) => {
                        return (
                          <TabPanel key={eItem.day} value={`${eIndex + 1}`}>
                            <div className="col-12 d-flex flex-column flex-wrap">
                              <div className="col-12 d-flex flex-row align-items-center gap-3 pb-3">
                                <label className="col-2 text-end">
                                  Holiday :
                                </label>
                                <FormControl>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={eItem.isHoliday}
                                        onChange={(event) =>
                                          this.onChangeTime(
                                            null,
                                            null,
                                            eItem.day,
                                            event.target.checked
                                          )
                                        }
                                        name="isHoliday"
                                      />
                                    }
                                  />
                                </FormControl>
                              </div>
                              <div className="col-12 d-flex flex-row align-items-center gap-3 mb-3">
                                <label className="col-2 text-end">
                                  Morning Shift Hours :
                                </label>
                                <div className="col-lg-4 col-md-5 col-sm-12">
                                  <DemoContainer components={["TimePicker"]}>
                                    <TimePicker
                                      label="Start Time"
                                      onChange={(newVal) =>
                                        this.onChangeTime(
                                          newVal,
                                          "bStartTime",
                                          eItem.day
                                        )
                                      }
                                      value={
                                        eItem?.bStartTime
                                          ? dayjs(eItem?.bStartTime)
                                          : null
                                      }
                                      onClose={() =>
                                        this.onCloseEvent("bStartTime", eIndex)
                                      }
                                      disabled={eItem.isHoliday}
                                    />
                                  </DemoContainer>
                                </div>
                                <div className="col-lg-4 col-md-5 col-sm-12">
                                  <DemoContainer components={["TimePicker"]}>
                                    <TimePicker
                                      label="End Time"
                                      onChange={(newVal) =>
                                        this.onChangeTime(
                                          newVal,
                                          "bEndTime",
                                          eItem.day
                                        )
                                      }
                                      minTime={
                                        eItem?.bStartTime
                                          ? dayjs(eItem?.bStartTime)
                                          : null
                                      }
                                      value={
                                        eItem?.bEndTime
                                          ? dayjs(eItem?.bEndTime)
                                          : null
                                      }
                                      disabled={eItem.isHoliday}
                                    />
                                  </DemoContainer>
                                </div>
                                <span className="col">
                                  <Button
                                    color="secondary"
                                    variant="contained"
                                    type="button"
                                    onClick={() =>
                                      this.resetTime(eItem.day, "morn")
                                    }
                                  >
                                    Reset
                                  </Button>
                                </span>
                              </div>
                              <Divider />
                              <div className="col-12 d-flex flex-row align-items-center gap-3 my-3">
                                <label className="col-2 text-end">
                                  Evening Shift Hours :
                                </label>
                                <div className="col-lg-4 col-md-5 col-sm-12">
                                  <DemoContainer components={["TimePicker"]}>
                                    <TimePicker
                                      label="Start Time"
                                      onChange={(newVal) =>
                                        this.onChangeTime(
                                          newVal,
                                          "lStartTime",
                                          eItem.day
                                        )
                                      }
                                      minTime={
                                        eItem?.bEndTime
                                          ? dayjs(eItem?.bEndTime)
                                          : null
                                      }
                                      value={
                                        eItem?.lStartTime
                                          ? dayjs(eItem?.lStartTime)
                                          : null
                                      }
                                      onClose={() =>
                                        this.onCloseEvent("lStartTime", eIndex)
                                      }
                                      disabled={eItem.isHoliday}
                                    />
                                  </DemoContainer>
                                </div>
                                <div className="col-lg-4 col-md-5 col-sm-12">
                                  <DemoContainer components={["TimePicker"]}>
                                    <TimePicker
                                      label="End Time"
                                      onChange={(newVal) =>
                                        this.onChangeTime(
                                          newVal,
                                          "lEndTime",
                                          eItem.day
                                        )
                                      }
                                      minTime={
                                        eItem?.lStartTime
                                          ? dayjs(eItem?.lStartTime)
                                          : null
                                      }
                                      value={
                                        eItem?.lEndTime
                                          ? dayjs(eItem?.lEndTime)
                                          : null
                                      }
                                      disabled={eItem.isHoliday}
                                    />
                                  </DemoContainer>
                                </div>
                                <span>
                                  <Button
                                    color="secondary"
                                    variant="contained"
                                    type="button"
                                    onClick={() =>
                                      this.resetTime(eItem.day, "noon")
                                    }
                                  >
                                    Reset
                                  </Button>
                                </span>
                              </div>
                            </div>
                          </TabPanel>
                        );
                      })}
                    </TabContext>
                    {this.state.settings._id && (
                      <div className="col-12 py-2 text-center">
                        <Grid
                          container
                          justifyContent="center"
                          item
                          className={classes.buttonsContainer}
                        >
                          <Button
                            aria-label={"Save"}
                            size="medium"
                            color={"primary"}
                            disabled={isSubmitting}
                            variant="contained"
                            onClick={this.saveAppWorkingHours}
                          >
                            Save
                          </Button>
                        </Grid>
                      </div>
                    )}
                  </>
                )}
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={this.state.expanded === "panel3"}
              onChange={(event) => this.handleAccordion("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  <b>Payment Configuration</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <form autoComplete="off">
                  <div className="col-12 d-lg-flex flex-lg-row flex-lg-nowrap justify-content-lg-between">
                    <div className="col-lg-6 col-md-12 px-3 pb-3">
                      <TextField
                        variant="standard"
                        name={"phoneLine1"}
                        value={nullToEmptyString(settings.phoneLine1)}
                        onChange={this.onChange}
                        label={"Phone Line 1"}
                        placeholder={"Enter phone line 1"}
                        required={true}
                        margin={"dense"}
                        autoComplete={"off"}
                        autoFocus
                        fullWidth
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 px-3 pb-3">
                      <TextField
                        variant="standard"
                        name={"phoneLine2"}
                        value={nullToEmptyString(settings.phoneLine2)}
                        onChange={this.onChange}
                        label={"Phone Line 2"}
                        placeholder={"Enter phone line 2"}
                        margin={"dense"}
                        autoComplete={"off"}
                        fullWidth
                      />
                    </div>
                  </div>

                  <div className="col-12 px-3">
                    <FormControl component="fieldset" required>
                      <FormLabel component="legend">
                        Active Environment
                      </FormLabel>
                      <RadioGroup
                        aria-label="paymentType"
                        name="paymentType"
                        value={
                          (settings.paymentConfiguration &&
                            settings.paymentConfiguration[0].config.length >
                              0 &&
                            settings.paymentConfiguration[0].config
                              .map((config) => {
                                if (config.isActive) {
                                  return config.type;
                                }
                                return null;
                              })
                              .find((type) => type !== null)) ||
                          ""
                        }
                        onChange={(event) =>
                          this.onChangePaymentMethod(event.target.value)
                        }
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <FormControlLabel
                          value="sandbox"
                          control={<Radio />}
                          label="Sandbox"
                          style={{ marginRight: "20px" }}
                        />
                        <FormControlLabel
                          value="production"
                          control={<Radio />}
                          label="Production"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>

                  <section id="Environment" className="mt-5">
                    <Container fluid>
                      <Row className="mb-10 justify-content-center">
                        <Col>
                          <Tabs defaultActiveKey="Sandbox" transition={false}>
                            <Tab
                              eventKey="Sandbox"
                              title={
                                <h1 style={{ fontSize: "15px" }}>Sandbox</h1>
                              }
                              style={{ color: "blue" }}
                            >
                              <Grid
                                container
                                spacing={2}
                                style={{ margin: "20px" }}
                              >
                                <Grid item xs={5}>
                                  <TextField
                                    variant="standard"
                                    name="sandbox_accessKey"
                                    value={
                                      settings.paymentConfiguration &&
                                      settings.paymentConfiguration[0]
                                        ?.config[0]?.accessKey
                                    }
                                    onChange={(e) =>
                                      this.onPaymentConfigChange(e, 0, 0)
                                    }
                                    label="Sandbox Application Id"
                                    placeholder="Enter Sandbox Application Id"
                                    margin="dense"
                                    autoComplete="off"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }} 
                                  />
                                </Grid>
                                <Grid item xs={5}>
                                  <input
                                    type="password"
                                    autoComplete="new-password"
                                    style={{ display: "none" }}
                                  />
                                  <TextField
                                    variant="standard"
                                    name="sandbox_accessToken"
                                    value={
                                      settings.paymentConfiguration &&
                                      settings.paymentConfiguration[0]
                                        ?.config[0]?.accessToken
                                    }
                                    onChange={(e) =>
                                      this.onPaymentConfigChange(e, 0, 0)
                                    }
                                    label="Sandbox Access Token"
                                    placeholder="Enter Sandbox Access Token"
                                    margin="dense"
                                    autoComplete="off"
                                    fullWidth
                                    type={showPassword ? "text" : "password"}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={
                                              this.togglePasswordVisibility
                                            }
                                            edge="end"
                                          >
                                            {showPassword ? (
                                              <VisibilityOff />
                                            ) : (
                                              <Visibility />
                                            )}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                    InputLabelProps={{ shrink: true }} 
                                  />
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                style={{ margin: "20px" }}
                              >
                                <Grid item xs={5}>
                                  <TextField
                                    variant="standard"
                                    name="sandbox_locationId"
                                    value={
                                      settings.paymentConfiguration &&
                                      settings.paymentConfiguration[0]
                                        ?.config[0]?.locationId
                                    }
                                    onChange={(e) =>
                                      this.onPaymentConfigChange(e, 0, 0)
                                    }
                                    label="Sandbox location Id"
                                    placeholder="Enter Sandbox location Id"
                                    margin="dense"
                                    autoComplete="off"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }} 
                                  />
                                </Grid>
                              </Grid>
                            </Tab>

                            <Tab
                              eventKey="Points"
                              title={
                                <h1 style={{ fontSize: "15px" }}>Production</h1>
                              }
                            >
                              <Grid
                                container
                                spacing={2}
                                style={{ margin: "20px" }}
                              >
                                <Grid item xs={5}>
                                  <TextField
                                    variant="standard"
                                    name="production_accessKey"
                                    value={
                                      settings.paymentConfiguration &&
                                      settings.paymentConfiguration[0]
                                        ?.config[1]?.accessKey
                                    }
                                    onChange={(e) =>
                                      this.onPaymentConfigChange(e, 0, 1)
                                    }
                                    label="Production Application Id"
                                    placeholder="Enter Production Application Id"
                                    margin="dense"
                                    autoComplete="off"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }} 
                                  />
                                </Grid>
                                <Grid item xs={5}>
                                  <input
                                    type="password"
                                    autoComplete="new-password"
                                    style={{ display: "none" }}
                                  />
                                  <TextField
                                    variant="standard"
                                    name="production_accessToken"
                                    value={
                                      settings.paymentConfiguration &&
                                      settings.paymentConfiguration[0]
                                        ?.config[1]?.accessToken
                                    }
                                    onChange={(e) =>
                                      this.onPaymentConfigChange(e, 0, 1)
                                    }
                                    label="Production Application Access Token"
                                    placeholder="Enter Production Access Token"
                                    margin="dense"
                                    autoComplete="off"
                                    fullWidth
                                    type={showPassword ? "text" : "password"}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={
                                              this.togglePasswordVisibility
                                            }
                                            edge="end"
                                          >
                                            {showPassword ? (
                                              <VisibilityOff />
                                            ) : (
                                              <Visibility />
                                            )}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                    InputLabelProps={{ shrink: true }} 
                                  />
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                style={{ margin: "20px" }}
                              >
                                <Grid item xs={5}>
                                  <TextField
                                    variant="standard"
                                    name="production_locationId"
                                    value={
                                      settings.paymentConfiguration &&
                                      settings.paymentConfiguration[0]
                                        ?.config[1]?.locationId
                                    }
                                    onChange={(e) =>
                                      this.onPaymentConfigChange(e, 0, 1)
                                    }
                                    label="Production location Id"
                                    placeholder="Enter Production location Id"
                                    margin="dense"
                                    autoComplete="off"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }} 
                                  />
                                </Grid>
                              </Grid>
                            </Tab>
                          </Tabs>
                        </Col>
                      </Row>
                    </Container>
                  </section>
                </form>
                <div className="col-12 py-2 text-end">
                  <Grid
                    container
                    justifyContent="center"
                    className={classes.buttonsContainer}
                  >
                    <Button
                      aria-label={"Save"}
                      size="medium"
                      color={"primary"}
                      disabled={isSubmitting}
                      variant="contained"
                      onClick={this.savePaymentConfig}
                    >
                      Save
                    </Button>
                  </Grid>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={this.state.expanded === "socialHandlesPanel"}
              onChange={(event) => this.handleAccordion("socialHandlesPanel")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  <b>Social Handles and Configurations</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="col-12">
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        variant="standard"
                        label="Facebook"
                        name="facebook"
                        value={settings?.facebook}
                        onChange={(event) =>
                          this.onChange({
                            target: {
                              name: "facebook",
                              value: event.target.value,
                            },
                          })
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        variant="standard"
                        label="Website"
                        name="website"
                        value={settings?.website}
                        onChange={(event) =>
                          this.onChange({
                            target: {
                              name: "website",
                              value: event.target.value,
                            },
                          })
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        variant="standard"
                        label="Instagram"
                        name="instagram"
                        value={settings?.instagram}
                        onChange={(event) =>
                          this.onChange({
                            target: {
                              name: "instagram",
                              value: event.target.value,
                            },
                          })
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        variant="standard"
                        label="Facebook App Id"
                        name="facebook_app_id"
                        value={settings?.facebook_app_id}
                        onChange={(event) =>
                          this.onChange({
                            target: {
                              name: "facebook_app_id",
                              value: event.target.value,
                            },
                          })
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        variant="standard"
                        label="Facebook Secret Key"
                        name="facebook_secret"
                        value={settings?.facebook_secret}
                        onChange={(event) =>
                          this.onChange({
                            target: {
                              name: "facebook_secret",
                              value: event.target.value,
                            },
                          })
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        variant="standard"
                        label="Facebook Access token"
                        name="facebook_access_token"
                        value={settings?.facebook_access_token}
                        onChange={(event) =>
                          this.onChange({
                            target: {
                              name: "facebook_access_token",
                              value: event.target.value,
                            },
                          })
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        variant="standard"
                        label="Facebook Page Id"
                        name="facebook_page_id"
                        value={settings?.facebook_page_id}
                        onChange={(event) =>
                          this.onChange({
                            target: {
                              name: "facebook_page_id",
                              value: event.target.value,
                            },
                          })
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.saveSocialHandles}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={this.state.expanded === "panel5"}
              onChange={(event) => this.handleAccordion("panel5")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5bh-content"
                id="panel5bh-header"
              >
                <Typography className="w-1/3">
                  <b>Delivery and Make Payment Screen Setup</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <b>Display Message Box</b>
                <form>
                  {/* First Row */}
                  <Grid container spacing={2} style={{ margin: "10px" }}>
                    <Grid item xs={12} sm={7}>
                      <TextField
                        variant="standard"
                        label="Info Message 1"
                        name="infomsg1"
                        value={nullToEmptyString(settings.infomsg1)}
                        onChange={this.onChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <TextField
                        variant="standard"
                        label="Info Message 2"
                        name="infomsg2"
                        value={nullToEmptyString(settings.infomsg2)}
                        onChange={this.onChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <TextField
                        variant="standard"
                        label="Info Message 3"
                        name="infomsg3"
                        value={nullToEmptyString(settings.infomsg3)}
                        onChange={this.onChange}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <b>Delivery Type </b>

                  <Grid container spacing={2} style={{ margin: "10px" }}>
                    <Grid item xs={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={settings.isPickupAtStore}
                            onChange={this.onChangeisPickupAtStore}
                            name="isPickupAtStore"
                            color="primary"
                          />
                        }
                        label="Pickup At Store"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={settings.isHomeDelivery}
                            onChange={this.onChangeisHomeDeliveryy}
                            name="isHomeDelivery"
                            color="primary"
                          />
                        }
                        label="Home Delivery"
                      />
                    </Grid>
                  </Grid>

                  <section id="menu" className="mt-5">
                    <Container fluid>
                      <Row className="mb-10 justify-content-center">
                        <Col>
                          <Tabs
                            defaultActiveKey="PickUpStore"
                            transition={false}
                          >
                            {settings.isPickupAtStore && (
                              <Tab
                                eventKey="PickUpStore"
                                title={
                                  <h1 style={{ fontSize: "15px" }}>
                                    Pickup At Store
                                  </h1>
                                }
                                style={{ color: "blue" }}
                              >
                                <form>
                                  <Grid
                                    container
                                    spacing={2}
                                    style={{ margin: "20px" }}
                                  >
                                    {/* Pickup */}
                                    <Grid item xs={3}>
                                      <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                          {" "}
                                          Payment Mode{" "}
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={nullToEmptyString(
                                            (settings.pickupAtStore &&
                                              settings.pickupAtStore
                                                .paymentMethods) ||
                                              []
                                          )}
                                          name="pickup"
                                          label="- Choose Payment Mode -"
                                          variant="standard"
                                          multiple // Enable multiple selection
                                          onChange={this.onChangePickup}
                                          renderValue={(selected) => (
                                            <div
                                              style={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                              }}
                                            >
                                              {selected.map((value) => {
                                                switch (value) {
                                                  case "PAY_ONLINE":
                                                    return (
                                                      <Chip
                                                        key={value}
                                                        label="Pay Online"
                                                      />
                                                    );
                                                  case "PAY_AT_STORE":
                                                    return (
                                                      <Chip
                                                        key={value}
                                                        label="Pay at Store"
                                                      />
                                                    );
                                                  default:
                                                    const partnerObject =
                                                      this.state.pickupPaymentMode.find(
                                                        (partner) =>
                                                          partner._id === value
                                                      );
                                                    return partnerObject ? (
                                                      <Chip
                                                        key={value}
                                                        label={
                                                          partnerObject.name
                                                        }
                                                      />
                                                    ) : null;
                                                }
                                              })}
                                            </div>
                                          )}
                                        >
                                          <MenuItem disabled>
                                            - Choose Pickup Payment Mode -
                                          </MenuItem>
                                          <MenuItem value="PAY_ONLINE">
                                            Pay Online
                                          </MenuItem>
                                          <MenuItem value="PAY_AT_STORE">
                                            Pay at Store
                                          </MenuItem>
                                          {this.state.pickupPaymentMode.map(
                                            (partner) => (
                                              <MenuItem
                                                key={partner._id}
                                                value={partner._id}
                                              >
                                                {partner.name}
                                              </MenuItem>
                                            )
                                          )}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </form>
                              </Tab>
                            )}

                            {settings.isHomeDelivery && (
                              <Tab
                                eventKey="Points"
                                title={
                                  <h1 style={{ fontSize: "15px" }}>
                                    Home Delivery
                                  </h1>
                                }
                              >
                                <Grid
                                  container
                                  spacing={2}
                                  style={{ margin: "20px" }}
                                >
                                  <Grid item xs={5}>
                                    <TextField
                                      variant="standard"
                                      name={"minimumAmount"}
                                      value={nullToEmptyString(
                                        settings.homeDelivery?.minimumAmount
                                      )}
                                      onChange={this.onChangeHomeDelivery}
                                      label={"Minimum delivery amount"}
                                      placeholder={
                                        "Enter Minimum delivery amount"
                                      }
                                      margin={"dense"}
                                      autoComplete={"off"}
                                      fullWidth
                                    />
                                  </Grid>

                                  <Grid item xs={5}>
                                    <TextField
                                      variant="standard"
                                      name={"deliveryCharge"}
                                      value={nullToEmptyString(
                                        settings.homeDelivery?.deliveryCharge
                                      )}
                                      onChange={this.onChangeHomeDelivery}
                                      label={"Delivery Charges"}
                                      placeholder={"Enter Delivery Charges"}
                                      margin={"dense"}
                                      autoComplete={"off"}
                                      fullWidth
                                    />
                                  </Grid>

                                  <Grid item xs={5}>
                                    <FormControl fullWidth>
                                      <InputLabel id="demo-simple-select-label">
                                        {" "}
                                        Allowed Pincode
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={
                                          Array.isArray(
                                            settings.homeDelivery
                                              ?.deliveryPincodes
                                          )
                                            ? settings.homeDelivery
                                                ?.deliveryPincodes
                                            : [
                                                settings.homeDelivery
                                                  ?.deliveryPincodes,
                                              ]
                                        }
                                        name="deliveryPincodes"
                                        label="- Choose Allowed Pincode -"
                                        variant="standard"
                                        multiple // Enable multiple selection
                                        onChange={this.onChangePincode}
                                        renderValue={(selected) => (
                                          <div
                                            style={{
                                              display: "flex",
                                              flexWrap: "wrap",
                                            }}
                                          >
                                            {selected.map((value) => {
                                              const pincodeObject =
                                                this.state.pincodes.find(
                                                  (pincode) =>
                                                    pincode._id === value
                                                );
                                              return pincodeObject ? (
                                                <Chip
                                                  key={value}
                                                  label={pincodeObject.pincode}
                                                />
                                              ) : null;
                                            })}
                                          </div>
                                        )}
                                      >
                                        <MenuItem disabled>
                                          - Allowed Pincode -
                                        </MenuItem>
                                        {this.state.pincodes.map(
                                          (deliveryPincodes) => (
                                            <MenuItem
                                              key={deliveryPincodes._id}
                                              value={deliveryPincodes._id}
                                            >
                                              {deliveryPincodes.pincode}
                                            </MenuItem>
                                          )
                                        )}
                                      </Select>
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={5}>
                                    <FormControl fullWidth>
                                      <InputLabel id="demo-simple-select-label">
                                        {" "}
                                        Payment Mode
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={nullToEmptyString(
                                          settings.homeDelivery
                                            ?.paymentMethods || []
                                        )}
                                        name="homeDelivery"
                                        label="- Choose Home Delivery -"
                                        variant="standard"
                                        multiple // Enable multiple selection
                                        onChange={this.onChangeSelfHomeDelivery}
                                        renderValue={(selected) => {
                                          if (!Array.isArray(selected)) {
                                            return null; // or return some default UI
                                          }

                                          return (
                                            <div
                                              style={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                              }}
                                            >
                                              {selected.map((value) => {
                                                switch (value) {
                                                  case "CASH_ON_DELIVERY":
                                                    return (
                                                      <Chip
                                                        key={value}
                                                        label="Cash on Delivery"
                                                      />
                                                    );
                                                  case "PAY_ONLINE":
                                                    return (
                                                      <Chip
                                                        key={value}
                                                        label="Pay Online"
                                                      />
                                                    );
                                                  default:
                                                    const partnerObject =
                                                      this.state.homeDeliveryPaymentMode.find(
                                                        (partner) =>
                                                          partner._id === value
                                                      );
                                                    return partnerObject ? (
                                                      <Chip
                                                        key={value}
                                                        label={
                                                          partnerObject.name
                                                        }
                                                      />
                                                    ) : null;
                                                }
                                              })}
                                            </div>
                                          );
                                        }}
                                      >
                                        <MenuItem disabled>
                                          - Choose Home Delivery Payment mode -
                                        </MenuItem>
                                        <MenuItem value="CASH_ON_DELIVERY">
                                          Cash on Delivery
                                        </MenuItem>
                                        <MenuItem value="PAY_ONLINE">
                                          Pay Online
                                        </MenuItem>
                                        {this.state.homeDeliveryPaymentMode.map(
                                          (partner) => (
                                            <MenuItem
                                              key={partner._id}
                                              value={partner._id}
                                            >
                                              {partner.name}
                                            </MenuItem>
                                          )
                                        )}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <FormControl style={{ width: "100%" }}>
                                      <InputLabel id="demo-simple-select-label">
                                        Delivery Type
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={nullToEmptyString(
                                          settings.homeDelivery?.deliveryType
                                        )}
                                        name="deliveryType"
                                        label="- Choose Delivery Type -"
                                        variant="standard"
                                        onChange={this.onChangedeliverytype}
                                      >
                                        <MenuItem selected disabled>
                                          - Choose Delivery Type -
                                        </MenuItem>
                                        <MenuItem value="">None</MenuItem>
                                        <MenuItem value="SELF">Self</MenuItem>
                                        {/* <MenuItem value="UBER">Uber</MenuItem>
                                      <MenuItem value="DOOR_DASH">Door Dash</MenuItem> */}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </Tab>
                            )}
                          </Tabs>
                        </Col>
                      </Row>
                    </Container>
                  </section>
                  {/* save button */}

                  <div className="col-12 py-2 text-end">
                    <Grid
                      container
                      justifyContent="center"
                      className={classes.buttonsContainer}
                    >
                      <Button
                        aria-label={"Save"}
                        size="medium"
                        color={"primary"}
                        disabled={isSubmitting}
                        variant="contained"
                        onClick={this.saveDeliverySetup}
                      >
                        Save
                      </Button>
                    </Grid>
                  </div>
                </form>
              </AccordionDetails>
            </Accordion>

            {/* Uber Settings */}
            <Accordion
              expanded={this.state.expanded === "panel6"}
              onChange={(event) => this.handleAccordion("panel6")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                disabled={!settings.isUberDeliveryEnabled}
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  <b>Uber Delivery</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        label="Uber Customer Id"
                        value={nullToEmptyString(
                          settings.uberSettings &&
                            settings.uberSettings.uberCustomerId
                        )}
                        onChange={(event) =>
                          this.uberSettingsChange(
                            "uberCustomerId",
                            event.target.value
                          )
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Uber Client Id"
                        value={nullToEmptyString(
                          settings.uberSettings &&
                            settings.uberSettings.uberClientId
                        )}
                        onChange={(event) =>
                          this.uberSettingsChange(
                            "uberClientId",
                            event.target.value
                          )
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Uber Client Secret"
                        value={nullToEmptyString(
                          settings.uberSettings &&
                            settings.uberSettings.uberClientSecret
                        )}
                        onChange={(event) =>
                          this.uberSettingsChange(
                            "uberClientSecret",
                            event.target.value
                          )
                        }
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <div className="col-12 py-2 text-end">
                  <Grid
                    container
                    justifyContent="center"
                    className={classes.buttonsContainer}
                  >
                    <Button
                      aria-label={"Save"}
                      size="medium"
                      color={"primary"}
                      disabled={isSubmitting}
                      variant="contained"
                      onClick={this.saveUberSettings}
                    >
                      Save
                    </Button>
                  </Grid>
                </div>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </div>
      </div>
    );
  }
}

// Component Properties
CreateOrUpdate.propTypes = {
  settings: PropTypes.object,
  save: PropTypes.func.isRequired,
  updateSettings: PropTypes.func.isRequired,
  detail: PropTypes.func.isRequired,
  getAllCurrencies: PropTypes.func.isRequired,
  getAllPincodes: PropTypes.func.isRequired,
  getAllCountryCodes: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  upload: PropTypes.func.isRequired,
};

// Component State
function createOrEditState(state) {
  return {
    settings: state.settings,
  };
}

export default connect(createOrEditState, {
  save,
  detail,
  getAllCountryCodes,
  getAllCurrencies,
  messageShow,
  updateSettings,
  upload,
  getAllPincodes,
})(withStyles(styles)(CreateOrUpdate));
