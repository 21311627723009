export const calculatePricingData = (productsList, storeData) => {
  if (!productsList || !Array.isArray(productsList) || productsList.length === 0) {
    return { chartOptions: {}, chartSeries: [], tableData: [] };
  }
  if (!storeData || !Array.isArray(storeData) || storeData.length === 0) {
    return { chartOptions: {}, chartSeries: [], tableData: [] };
  }

  const productNames = [...new Set(productsList.map(product => product.name))];
  const storeProducts = storeData.filter(product => productNames.includes(product.name));
  
  const pricingData = productNames.map(productName => {
    const relevantProducts = productsList.filter(product => product.name === productName);
    const storeProduct = storeProducts.find(product => product.name === productName);
    const prices = [...relevantProducts.map(product => product.price), storeProduct?.price].filter(price => price !== null && price !== undefined);
    
    const minPrice = prices.length > 0 ? Math.min(...prices) : null;
    const maxPrice = prices.length > 0 ? Math.max(...prices) : null;
    const averagePrice = prices.length > 0 ? calculateAverage(prices) : null;
    const medianPrice = prices.length > 0 ? calculateMedian(prices) : null;
    
    const storePrice = storeProduct ? storeProduct.price : null;
    const priceDifference = storePrice !== null && medianPrice !== null ? storePrice - medianPrice : null;

    return {
      productName,
      price: storePrice,
      priceDifference,
      minPrice,
      maxPrice,
      averagePrice,
    };
  });

  const chartOptions = {
    chart: {
      type: 'line',
      height: 600,
      zoom: {
        enabled: true,
        type: 'x',
      },
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: []
        },
      }
    },
    xaxis: {
      categories: productNames,
      tickAmount: 'dataPoints',
    },
    yaxis: [
      {
        title: {
          text: 'Price'
        },
        labels: {
          formatter: function(val) {
            return val !== undefined && val !== null ? `$${val.toFixed(2)}` : 'N/A';
          }
        },
      },
    ],
    title: {
      text: 'Store Price Comparison',
      align: 'center'
    },
    legend: {
      position: 'top'
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    stroke: {
      width: [0, 0, 3]
    },
    plotOptions: {
      bar: {
        columnWidth: '50%'
      }
    }
  };

  const chartSeries = [
    { name: 'Min Price', type: 'bar', data: pricingData.map(d => d.minPrice) },
    { name: 'Max Price', type: 'bar', data: pricingData.map(d => d.maxPrice) },
    { 
      name: 'Average Price', 
      type: 'line', 
      data: pricingData.map(d => d.averagePrice),
      yAxisIndex: 1,
    },
  ];

  const tableData = pricingData.map(d => ({
    productName: d.productName,
    price: d.price !== null ? `$${d.price.toFixed(2)}` : 'N/A',
    priceDifference: d.priceDifference !== null ? 
      (d.priceDifference < 0 ? `-$${Math.abs(d.priceDifference).toFixed(2)}` : `$${d.priceDifference.toFixed(2)}`) : 'N/A',
    minPrice: d.minPrice !== null ? `$${d.minPrice.toFixed(2)}` : 'N/A',
    maxPrice: d.maxPrice !== null ? `$${d.maxPrice.toFixed(2)}` : 'N/A',
    averagePrice: d.averagePrice !== null ? `$${d.averagePrice.toFixed(2)}` : 'N/A',
  }));

  return { chartOptions, chartSeries, tableData };
};

const calculateMedian = (numbers) => {
  const sorted = [...numbers].sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);

  if (sorted.length % 2 === 0) {
    return (sorted[middle - 1] + sorted[middle]) / 2;
  } else {
    return sorted[middle];
  }
};

const calculateAverage = (numbers) => {
  const sum = numbers.reduce((a, b) => a + b, 0);
  return sum / numbers.length;
};