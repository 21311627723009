// Imports
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// UI Imports
import Grid from "@material-ui/core/Grid/Grid";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@mui/material/Button";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import Tooltip from '@material-ui/core/Tooltip'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import UploadIcon from "@mui/icons-material/Upload";
import CheckIcon from "@mui/icons-material/Check";
import IconArrowBack from "@material-ui/icons/ArrowBack";
import IconClose from "@material-ui/icons/Close";
import Checkbox from "@mui/material/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

// App Imports
import {
  nullToEmptyString,
  capitalizeFirstLetter,
  subString,
} from "../../../setup/helpers";
import { upload, messageShow } from "../../common/api/actions";
import SectionPaper from "../../common/SectionPaper";
import Loading from "../../common/Loading";
import ImageComp from "../../common/Image";
import { list as listCategories } from "../../category/api/actions/query";
import { listFeatured as listFeaturedCategories } from "../../product/api/actions/query";
import { list, detail } from "../api/actions/query";
import { createOrUpdate } from "../api/actions/mutation";
import routes, { getImageSource } from "../api/routes";

// Component
class CreateOrUpdate extends Component {
  constructor(props) {
    super(props);
    
     this.redirect = {
      url: "",
      screen: "",
      categoryId: null,
      productId: null,
    };
  
    this.banner = {
      _id: "",
      name: "",
      description: "",
      image: "default.jpg",
      sort: 1,
      type: "url",
      redirect: { ...this.redirect },
      day:{...this.day},
    };

    this.state = {
      isLoading: false,
      isLoadingSubmit: false,
      isUploadingFile: false,
      products: [],
      banner: this.banner,
      previewImage: null,
    };
  }
  SetDayValue = ({ target: { name, checked } }) => {
    const { banner } = this.state;
    this.setState({
      banner: {
        ...banner,
        day: {
          ...banner.day,
          [name]: checked,
        },
      },
    });
  };

  componentDidMount() {
    const {
      match: {
        params: { bannerId },
      },
    } = this.props;

    // Get categories
    this.getCategories();

    // Get products
    this.getProducts();

    // Get banner details if edit
    if (bannerId) {
      this.getBanner(bannerId);
    }
  }

  getCategories = () => {
    const { listCategories } = this.props;

    listCategories();
  };

  getProducts = async () => {
    const { listFeaturedCategories } = this.props;

    try {
      const { data } = await listFeaturedCategories();

      if (data.success) {
        this.setState({
          products: data.data,
        });
      }
    } catch (error) {}
  };

  getBanner = async (bannerId) => {
    const { detail, messageShow } = this.props;
    
    this.isLoadingToggle(true);

    try {
      const { data } = await detail({ bannerId });

      if (data.success) {
        this.setState({
          banner: data.data,
        });
      }
    } catch (error) {
      messageShow("Some error occurred. Please try again.");
    } finally {
      this.isLoadingToggle(false);
    }
  };

  onCreateOrUpdate = async (event) => {
  
    event.preventDefault();

    const { createOrUpdate, messageShow, history } = this.props;

    const { banner } = this.state;

    this.isLoadingSubmitToggle(true);

    try {
      const { data } = await createOrUpdate(banner);

      this.isLoadingSubmitToggle(false);

      messageShow(data.message);

      if (data.success) {
        history.push(routes.bannerList.path);
      }
    } catch (error) {
      this.isLoadingSubmitToggle(false);

      messageShow("Some error occurred. Please try again.");
    }
  };

  isLoadingToggle = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  isLoadingSubmitToggle = (isLoadingSubmit) => {
    this.setState({
      isLoadingSubmit,
    });
  };

  isUploadingFileToggle = (isUploadingFile) => {   
    this.setState({
      isUploadingFile,
    });
  };

  onUpload = async (event) => {
    const { upload, messageShow } = this.props;

    messageShow("Uploading file, please wait...");

    this.isUploadingFileToggle(true);

    let file = new FormData();
    file.append("type", "banner");
    file.append("name", this.state.banner.name);
    file.append("file", event.target.files[0]);

    const fileReader = new FileReader();
    fileReader.onload = () => {
      const fileResult = fileReader.result;
      let dataAsImg = new Image();
      dataAsImg.src = fileResult;
      this.setState({ previewImage: dataAsImg.src });
    };
    fileReader.readAsDataURL(event.target.files[0]);

    // Upload image
    try {
      const { data } = await upload(file);

      if (data.success) {
        this.props.messageShow("File uploaded successfully.");

        const { banner } = this.state;

        setTimeout(() => {
          banner.image = data.file;
          this.setState({
            banner,
          });
        }, 1000);
      } else {
        messageShow("There was some error. Please try again.");
      }
    } catch (error) {
      messageShow("There was some error. Please try again.");
    } finally {
      this.isUploadingFileToggle(false);
    }
  };

  onChange = ({ target: { name, value } }) => {
    const { banner } = this.state;

    banner[name] = name !== "type" ? capitalizeFirstLetter(value) : value;

    this.setState({
      banner,
    });
  };

  onChangeRedirect = ({ target: { name, value } }) => {
    const { banner } = this.state;

    banner.redirect = { ...this.redirect };

    banner.redirect[name] = value;

    this.setState({
      banner,
    });
  };

  render() {
    const {
      match: {
        params: { bannerId },
      },
      categories,
      classes,
    } = this.props;
    const { banner, isLoading, isLoadingSubmit, isUploadingFile, products } =
      this.state;

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <Link to={routes.bannerList.path}>
            <IconButton className={classes.menuButton} color="inherit">
              <IconArrowBack />
            </IconButton>
          </Link>
          
          <Typography variant="h6" color="inherit" className={classes.grow}>
            {bannerId ? "Edit" : "Create"} Banner
          </Typography>
        </Toolbar>

        <Grid item xs={12} lg={6}>
          <SectionPaper padding>
            {isLoading ? (
              <Loading />
            ) : (
              <form onSubmit={this.onCreateOrUpdate}>
                {/* Input - Name */}
                <Grid item xs={12}>
                  <TextField
                    name={"name"}
                    value={nullToEmptyString(banner.name)}
                    onChange={this.onChange}
                    label={"Name"}
                    placeholder={"Enter banner name"}
                    required={true}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                    autoFocus
                  />
                </Grid>

                {/* Input - Description */}
                <Grid item xs={12}>
                  <TextField
                    name={"description"}
                    value={nullToEmptyString(banner.description)}
                    onChange={this.onChange}
                    label={"Description"}
                    placeholder={"Enter description"}
                    margin={"dense"}
                    autoComplete={"off"}
                    multiline
                    fullWidth
                  />
                </Grid>

                {/* Type */}
                <Grid item xs={12} style={{ marginTop: 14 }}>
                  <FormControl component="fieldset" required>
                    <FormLabel component="legend">Type</FormLabel>
                    <RadioGroup
                      aria-label="type"
                      name="type"
                      value={banner.type}
                      onChange={this.onChange}
                      required
                      row
                    >
                      <FormControlLabel
                        value="url"
                        control={<Radio color="primary" />}
                        label="URL"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="screen"
                        control={<Radio color="primary" />}
                        label="Screen"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="category"
                        control={<Radio color="primary" />}
                        label="Category"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="product"
                        control={<Radio color="primary" />}
                        label="Product"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                {/* Input - Redirect */}
                <Grid item xs={12}>
                  {
                    {
                      url: (
                        <TextField
                          name={"url"}
                          value={nullToEmptyString(banner.redirect.url)}
                          onChange={this.onChangeRedirect}
                          label={"URL"}
                          placeholder={
                            "Enter a website URL (eg: http://datumailabs.com)"
                          }
                          required={true}
                          margin={"dense"}
                          autoComplete={"off"}
                          fullWidth
                        />
                      ),

                      screen: (
                        <FormControl
                          fullWidth
                          style={{ marginTop: 5, marginBottom: 4 }}
                        >
                          <InputLabel htmlFor={"screen"}>
                            Select screen
                          </InputLabel>
                          <Select
                            value={nullToEmptyString(banner.redirect.screen)}
                            onChange={this.onChangeRedirect}
                            inputProps={{ name: "screen", id: "screen" }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value={"categories"}>Categories</MenuItem>
                            <MenuItem value={"signup"}>Signup</MenuItem>
                          </Select>
                        </FormControl>
                      ),

                      category: (
                        <FormControl
                          fullWidth
                          style={{ marginTop: 5, marginBottom: 4 }}
                        >
                          <InputLabel htmlFor={"categoryId"}>
                            Select category
                          </InputLabel>
                          <Select
                            value={nullToEmptyString(
                              banner.redirect.categoryId
                            )}
                            onChange={this.onChangeRedirect}
                            inputProps={{
                              name: "categoryId",
                              id: "categoryId",
                            }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {categories.isLoading ? (
                              <MenuItem disabled>
                                <em>loading</em>
                              </MenuItem>
                            ) : (
                              categories.list.map(
                                ({ category, subCategories }) => [
                                  <MenuItem
                                    key={category._id}
                                    value={category._id}
                                    disabled
                                  >
                                    {category.name}
                                  </MenuItem>,
                                  subCategories.map((subCategory) => (
                                    <MenuItem
                                      key={subCategory._id}
                                      value={subCategory._id}
                                      style={{ paddingLeft: 40 }}
                                    >
                                      {subCategory.name}
                                    </MenuItem>
                                  )),
                                ]
                              )
                            )}
                          </Select>
                        </FormControl>
                      ),

                      product: (
                        <FormControl
                          fullWidth
                          style={{ marginTop: 5, marginBottom: 4 }}
                        >
                          <InputLabel htmlFor={"productId"}>
                            Select a featured product
                          </InputLabel>
                          <Select
                            value={nullToEmptyString(banner.redirect.productId)}
                            onChange={this.onChangeRedirect}
                            inputProps={{ name: "productId", id: "productId" }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {products.map((product) => (
                              <MenuItem
                                key={product._id}
                                value={product._id}
                              >{`${subString(product.name, 40)} / ₹${
                                product.price
                              }`}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ),
                    }[banner.type]
                  }
                </Grid>

                {/* Input - Sort */}
                <Grid item xs={12}>
                  <TextField
                    name={"sort"}
                    type={"number"}
                    value={nullToEmptyString(banner.sort)}
                    onChange={this.onChange}
                    label={"Sort"}
                    placeholder={"Enter sort"}
                    required={true}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                  />
              </Grid>
                <InputLabel style={{ marginTop: 14 }} >Days</InputLabel>
                <Grid container alignItems="center" spacing={1} style={{ marginTop: 14 }}>
                <Checkbox name="mon" checked={banner.day.mon} onChange={this.SetDayValue} color="primary" />
                 <InputLabel>Mon</InputLabel>
                <Checkbox name="tue" checked={banner.day.tue} onChange={this.SetDayValue} color="primary" />
                <InputLabel>Tue</InputLabel>
                <Checkbox name="wed" checked={banner.day.wed} onChange={this.SetDayValue} color="primary" />
                <InputLabel>Wed</InputLabel>
                <Checkbox name="thu" checked={banner.day.thu} onChange={this.SetDayValue} color="primary" />
                <InputLabel>Thur</InputLabel>
                <Checkbox name="fri" checked={banner.day.fri} onChange={this.SetDayValue} color="primary" />
                <InputLabel>Fri</InputLabel>
                <Checkbox name="sat" checked={banner.day.sat} onChange={this.SetDayValue} color="primary" />
                <InputLabel>Sat</InputLabel>
                <Checkbox name="sun" checked={banner.day.sun} onChange={this.SetDayValue} color="primary" />
                <InputLabel>Sun</InputLabel>
              
               </Grid>

                {/* Input - Image */}
                <Grid
                  container
                  spacing={10}
                  className={classes.buttonUpload}
                  alignItems="center"
                >
                  <Grid item md={6}>
                    {!this.state.previewImage ? (
                      <ImageComp
                        src={getImageSource(`${banner.image}`)}
                        defaultSrc={getImageSource()}
                        size={"100%"}
                      />
                    ) : (
                      <img
                        height="auto"
                        width="100%"
                        src={this.state.previewImage}
                        alt="preview_banner"
                      ></img>
                    )}
                  </Grid>
                  <Grid item md={6}>
                    <input
                      accept={"image/png,image/jpeg"}
                      style={{ display: "none" }}
                      id={"contained-button-file"}
                      type={"file"}
                      onChange={this.onUpload}
                    />

                  <label htmlFor={"contained-button-file"}>
                      <Button
                        color="primary"
                        component="span"
                        disabled={isUploadingFile}
                        aria-label="Upload"
                        startIcon={<UploadIcon />}
                      >
                       UPLOAD IMAGE
                      </Button>
                  </label>
                  </Grid>
                </Grid>

                {/* Button - Save */}
                <Grid item xs={12} className={classes.buttonsContainer}>
                  <Link to={routes.bannerList.path}>
                    <Tooltip title="Cancel" arrow>
                      <Button type={"button"} aria-label={"Close"} color={"error"} startIcon={<CancelIcon />}></Button>
                    </Tooltip>
                  </Link>
                  <Tooltip title="Save" arrow> 
                    <Button
                      type={"submit"}
                      aria-label={"Save"}
                      startIcon={<SaveIcon />}
                      color={"primary"}
                      disabled={isLoadingSubmit}
                    >
                    </Button>
                  </Tooltip>
                </Grid>
              </form>
            )}
          </SectionPaper>
        </Grid>
      </div>
    );
  }
}

// Component Properties
CreateOrUpdate.propTypes = {
  categories: PropTypes.object.isRequired,
  list: PropTypes.func.isRequired,
  detail: PropTypes.func.isRequired,
  createOrUpdate: PropTypes.func.isRequired,
  listCategories: PropTypes.func.isRequired,
  listFeaturedCategories: PropTypes.func.isRequired,
  upload: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

// Component State
function createOrEditState(state) {
  return {
    categories: state.categories,
  };
}

export default connect(createOrEditState, {
  list,
  detail,
  createOrUpdate,
  listCategories,
  listFeaturedCategories,
  upload,
  messageShow,
})(withStyles(styles)(CreateOrUpdate));
