const styles = theme => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 3),
  },
  searchFieldsAll: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  /*select: {
    marginRight: theme.spacing(2),
    minWidth: 100,
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },*/
  joinedSearchAll: {
    display: 'flex',
    alignItems: 'center',
  },
  calendarPopover: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    marginRight: theme.spacing(1),
    alignItems: 'center',
  },
  downloadButton: {
    marginLeft: theme.spacing(2),
  },
});

export default styles;
