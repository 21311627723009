import axios from "axios";
import { API_URL } from "../../../../setup/config/env";


// Email
export const fetchEmails = async () => {
    try {
        const response = await axios.get(`${API_URL}/api/messaging/email`,);
        return response.data;
    } catch (error) {
        return { error: error.response?.data?.error || "Network Error" };
    }
}

export const fetchEmailsById = async(id) => {
    try {
        const response = await axios.get(`${API_URL}/api/messaging/email/${id}`);
        return response.data;
    } catch (error) {
        return { error: error.response?.data?.error || "Network Error" };
    }
}

export const handleRefreshEmail = async (id)=>{
    try{
        const response = await axios.get(`${API_URL}/api/messaging/email/${id}/status`)
        return {
            totalCount: response.data.customersCount,
            sentCount: response.data.emailSent, 
            status: response.data.emailStatus
        }
    }
    catch(error){
        return { error: error.response?.data?.error || "Network Error" };
    }
}

// Notification

export const fetchNotification = async () => {
    try {
        const response = await axios.get(`${API_URL}/api/messaging/push-notification`);
        return response.data;
    } catch (error) {
        return { error: error.response?.data?.error || "Network Error" };
    }
}

export const fetchNotificationById = async(id) => {
    try {
        const response = await axios.get(`${API_URL}/api/messaging/push-notification/${id}`);
        return response.data;
    } catch (error) {
        return { error: error.response?.data?.error || "Network Error" };
    }
}

export const handleRefreshNotification = async (id)=>{
    try{
        const response = await axios.get(`${API_URL}/api/messaging/push-notification/${id}/status`)
        return response.data
    }
    catch(error){
        return { error: error.response?.data?.error || "Network Error" };
    }
}

// /Facebook post

export const fetchPosts = async () => {
    try {
        const response = await axios.get(`${API_URL}/api/messaging/fb-post`);
        return response.data;
    } catch (error) {
        return { error: error.response?.data?.error || "Network Error" };
    }
}

export const fetchPostsById = async(id) => {
    try {
        const response = await axios.get(`${API_URL}/api/messaging/fb-post/${id}`);
        return response.data;
    } catch (error) {
        return { error: error.response?.data?.error || "Network Error" };
    }
}
export const handleRefreshPosts = async (id)=>{
    try{
        const response = await axios.get(`${API_URL}/api/messaging/fb-post/${id}/status`)
        return response.data
    }
    catch(error){
        return { error: error.response?.data?.error || "Network Error" };
    }
}

// SendGrid API functions
export const getBouncedEmails = async () => {
    try {
        const response = await axios.get(`${API_URL}/api/messaging/getBounced`)
        return response.data;
    } catch (error) {
        return { error: error.response?.data?.error || "Network Error" };
    }
};

export const getSpamReport = async () => {
    try {
        const response = await axios.get(`${API_URL}/api/messaging/getSpamReport`)
        return response.data;
    } catch (error) {
        return { error: error.response?.data?.error || "Network Error" };
    }
};
