import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@material-ui/core/Tooltip'
import Loading from '../common/Loading';
import EmptyMessage from '../common/EmptyMessage';
import DeleteIcon from '@mui/icons-material/Delete';
import { addFaq, updateFaq, deleteFaq } from "./api/actions/mutation";
import { getAllFaqs } from './api/actions/query';
import { messageShow } from '../common/api/actions';
import {
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Fab
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./styles";
import SectionPaper from "../common/SectionPaper";

class FaqManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faqs: [],
      editStates: {},
      newFaq: {
        prompt: '',
        answer: '',
        isEditing: false
      }
    };
  }

  componentDidMount() {
    this.fetchFaqs();
  }

  fetchFaqs = async () => {
    const { getAllFaqs } = this.props;
    try {
      const { data } = await getAllFaqs();
      if (data.success) {
        this.setState({ faqs: data.data });
      }
    } catch (error) {
      console.error("Error fetching FAQs:", error);
    }
  };

  handleEditClick = (id) => {
    const { faqs } = this.state;
    const faq = faqs.find(faq => faq._id === id);
    this.setState((prevState) => ({
      editStates: {
        ...prevState.editStates,
        [id]: {
          isEditing: true,
          prompt: faq.prompt,
          answer: faq.answer,
        },
      },
    }));
  };

  handleSaveClick = async (id) => {
    const { editStates } = this.state;
    const { prompt, answer } = editStates[id];
    try {
      await this.props.updateFaq({ id, prompt, answer });
      this.props.messageShow("FAQ updated successfully.");
      this.fetchFaqs(); 
    } catch (error) {
      this.props.messageShow("Error updating FAQ.");
    } finally {
      this.setState((prevState) => ({
        editStates: {
          ...prevState.editStates,
          [id]: {
            ...prevState.editStates[id],
            isEditing: false,
          },
        },
      }));
    }
  };

  handleCancelClick = (id) => {
    this.setState((prevState) => ({
      editStates: {
        ...prevState.editStates,
        [id]: {
          ...prevState.editStates[id],
          isEditing: false,
        },
      },
    }));
  };

  handleChange = (id, field, value) => {
    this.setState((prevState) => ({
      editStates: {
        ...prevState.editStates,
        [id]: {
          ...prevState.editStates[id],
          [field]: value,
        },
      },
    }));
  };

  handleNewFaqChange = (field, value) => {
    this.setState((prevState) => ({
      newFaq: {
        ...prevState.newFaq,
        [field]: value,
      },
    }));
  };

  handleAddFaq = async () => {
    const { newFaq } = this.state;
    if (newFaq.prompt && newFaq.answer) {
      try {
        await this.props.addFaq(newFaq);
        this.props.messageShow("FAQ added successfully.");
        this.fetchFaqs();
        this.setState({
          newFaq: { prompt: '', answer: '', isEditing: false }
        });
      } catch (error) {
        this.props.messageShow("Error adding FAQ.");
      }
    } else {
      this.props.messageShow("Please fill in both fields.");
    }
  };

  handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this FAQ?")) {
      this.props.deleteFaq({ id }).then(() => {
        this.props.messageShow("FAQ deleted successfully.");
        this.fetchFaqs();
      });
    }
  };

  render() {
    const { classes, isLoading } = this.props;
    const { faqs, editStates, newFaq } = this.state;

    return (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between", padding: "18px" }}>
          <Typography variant="h5">FAQ Management</Typography>
          <Tooltip title="Create FAQ" arrow>
            <Button
              color="primary"
              size='big'
              className={classes.addButton}
              onClick={() => this.setState({ newFaq: { ...newFaq, isEditing: true } })}
              startIcon={<AddIcon />}
            >
            </Button>
          </Tooltip>
        </div>
        
        <SectionPaper>
          {
            isLoading
              ? <Loading />
              : faqs.length === 0
                ? <EmptyMessage message={'You do not have any FAQ yet.'} />
                : <TableContainer>
                    <Table className={classes.table} padding={'normal'}>
                      <TableHead>
                        <TableRow>
                          <TableCell>PROMPT</TableCell>
                          <TableCell>ANSWER</TableCell>
                          <TableCell align="center">ACTIONS</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {faqs.map((faq) => {
                          const isEditing = editStates[faq._id]?.isEditing;
                          return (
                            <TableRow key={faq._id}>
                              <TableCell>
                                {isEditing ? (
                                  <TextField
                                    value={editStates[faq._id]?.prompt || ''}
                                    onChange={(e) => this.handleChange(faq._id, 'prompt', e.target.value)}
                                  />
                                ) : faq.prompt}
                              </TableCell>
                              <TableCell>
                                {isEditing ? (
                                  <TextField
                                    value={editStates[faq._id]?.answer || ''}
                                    onChange={(e) => this.handleChange(faq._id, 'answer', e.target.value)}
                                  />
                                ) : faq.answer}
                              </TableCell>
                              <TableCell align="center">
                                {isEditing ? (
                                  <>
                                    <Tooltip title="Cancel" arrow>
                                      <Button
                                        color="error"
                                        onClick={() => this.handleCancelClick(faq._id)}
                                        startIcon={<CancelIcon />}
                                      >
                                      </Button>
                                    </Tooltip>
                                    <Tooltip title="Save" arrow>
                                      <Button
                                        color="primary"
                                        onClick={() => this.handleSaveClick(faq._id)}
                                        startIcon={<SaveIcon />}
                                        style={{ marginRight: "10px" }}
                                      >
                                      </Button>
                                    </Tooltip>
                                  </>
                                ) : (
                                  <>
                                    <Tooltip title="Edit" arrow>
                                      <Button
                                        color="primary"
                                        onClick={() => this.handleEditClick(faq._id)}
                                        style={{ marginRight: "10px" }}
                                      >
                                        <EditIcon />
                                      </Button>
                                    </Tooltip>
                                    <Tooltip title="Delete" arrow>
                                      <Button
                                        color="error"
                                        onClick={() => this.handleDelete(faq._id)}
                                      >
                                        <DeleteIcon />
                                      </Button>
                                    </Tooltip>
                                  </>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {newFaq.isEditing && (
                          <TableRow>
                            <TableCell>
                              <TextField
                                value={newFaq.prompt}
                                onChange={(e) => this.handleNewFaqChange('prompt', e.target.value)}
                                placeholder="Enter prompt"
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                value={newFaq.answer}
                                onChange={(e) => this.handleNewFaqChange('answer', e.target.value)}
                                placeholder="Enter answer"
                              />
                            </TableCell>
                            <TableCell>
                              <Tooltip title="Cancel" arrow>
                                <Button
                                  color="error"
                                  onClick={() => this.setState({ newFaq: { ...newFaq, isEditing: false } })}
                                  startIcon={<CloseIcon />}
                                >
                                </Button>
                              </Tooltip>
                              <Tooltip title="Save" arrow>
                                <Button
                                  color="primary"
                                  onClick={this.handleAddFaq}
                                  startIcon={<SaveIcon />}
                                  style={{ marginRight: "10px" }}
                                >
                                </Button>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
          }
        </SectionPaper>
      </div>    
    );
  }
}

FaqManagement.propTypes = {
  faqs: PropTypes.array.isRequired,
  getAllFaqs: PropTypes.func.isRequired,
  addFaq: PropTypes.func.isRequired,
  deleteFaq: PropTypes.func.isRequired,
  updateFaq: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    faqs: state.faqs,
  };
}



export default connect(mapStateToProps, {
  getAllFaqs,
  addFaq,
  deleteFaq,
  updateFaq, 
  messageShow,
})(withStyles(styles)(FaqManagement));
