export const calculateForecastData = (data, forecast) => {
  const dates = data.map(d => new Date(d.ds).getTime());
  const actuals = data.map(d => parseFloat(d.Actuals));
  const forecasts = data.map(d => parseFloat(d.Forecast));

  const chartOptions = {
    chart: {
      type: 'line',
      height: 600
    },
    xaxis: {
      type: 'datetime',
      categories: dates,
      labels: {
        format: 'MMM dd yyyy'
      },
      tickAmount: 'dataPoints',
      scrollable: true
    },
    yaxis: {
      title: {
        text: (forecast === "Sales Forecast" ? "Sales Amount" : "Order")
      },
      labels: {
        formatter: function(val) {return Math.round(val)}
      },
      min: 0,
      tickAmount: 5,
    },
    title: {
      text: forecast,
      align: 'center'
    },
    legend: {
      position: 'top'
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '100%',
        endingShape: 'rounded'
      },
    },
  };

  const chartSeries = [
    {
      name: 'Actuals',
      data: actuals
    },
    {
      name: 'Forecast',
      data: forecasts
    }
  ];

  const tableData = data.map(d => ({
    date: new Date(d.ds).toLocaleDateString(),
    actuals: parseFloat(d.Actuals).toFixed(2),
    forecast: parseFloat(d.Forecast).toFixed(2),
    predictionType: d.PredictionType
  }));

  return { chartOptions, chartSeries, tableData };
};