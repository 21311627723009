// Imports
import React, { PureComponent } from 'react'
import { GoogleReCaptchaProvider,GoogleReCaptcha } from 'react-google-recaptcha-v3';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// UI Import
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import CheckIcon from '@mui/icons-material/Check';
import IconCheckCircle from '@material-ui/icons/CheckCircle'
import Button from '@mui/material/Button';
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import IconArrowBack from "@material-ui/icons/ArrowBack";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

// App Imports
import { nullToEmptyString } from '../../../setup/helpers'
import { messageShow } from '../../common/api/actions'
import { checkMail } from '../api/actions/query'
import { sendMail } from '../api/actions/mutation'
import { userForgotPasswordEmail } from '../api/actions/mutation'
import { adminForgotPasswordReset } from '../api/actions/mutation'
import routes from '../api/routes';

class ForgotPassword extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      emailOrMobile:'',
      otp:'',
      password:'',
      confirmPassword:'',
      mailverified:'true',
      showPassword: false,
      isSubmitted: false,

      isSubmitting: false,
      isVerified: true
    }
  }

  onType = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  sendEmailId = async event => {
    event.preventDefault()
    const { checkMail, messageShow } = this.props
    const { emailOrMobile } = this.state
    const email = emailOrMobile;

    try {
      const status = await checkMail({ email })
      if (status.success) {
        this.setState({
          isSubmitted: true
        })
        this.sendOtp(event)
      }
    } catch(error) {
      messageShow(error)
    }
  }

  sendOtp = async event => {
    event.preventDefault()
    const { userForgotPasswordEmail, messageShow } = this.props
    const { emailOrMobile, isVerified } = this.state

    if (isVerified) {
      const status = await userForgotPasswordEmail({ emailOrMobile })
      if (status.data.success) {
        this.setState({
          isSubmitted: true
        })
        messageShow(status.data.message)
      }
      else {
        messageShow("error while sending mail")
      } 
      } else {
      messageShow("error while captcha validation")
    } 
  }

  sendOtpAndPassword = async event => {
    event.preventDefault()
    const { adminForgotPasswordReset, messageShow,history } = this.props
    const { emailOrMobile , otp , password , confirmPassword  } = this.state
    if(otp.toString().length =="4"){
      if(password.toString().length >"5"){
        if (password === confirmPassword ){
          try {
                const data = await adminForgotPasswordReset({ emailOrMobile , otp , password })
                if (data.data.success) {
                  messageShow(data.data.message)
                  history.push(routes.userLogin.path);

                }
                else{
                  messageShow(data.data.message)
                }
              }
              catch(error) {messageShow(error)}
        }
        else{alert("Password and Confirm Password should be same !!")}
      }
      else{alert("Password should be ateast 6 characters")}
    }
    else{alert("Please enter 4 digit otp !!")}
}


  
  togglePasswordVisibility = () => {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    }))
  }
  back = () => {
    const { history } = this.props;

    if (history.length > 2) {
      history.goBack();
    } else {
      history.push(routes.userLogin.path);
    }
  };
  back1 = () => {
    const { history } = this.props;
    this.setState({
      isSubmitted: false
    })
  };

  render() {
    const { emailOrMobile,otp, isSubmitted,password,confirmPassword, isSubmitting,showPassword } = this.state
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <Paper className={classes.container}>
          {
            isSubmitted
            ?
           <div>
              <Typography
                variant={'subtitle1'}
                color={'inherit'}
                className={classes.heading}
              >
                <div style={{ display: 'flex', justifyContent: 'flex-start' ,}}>
                <IconButton
                  className={classes.menuButton}
                  color="inherit"
                  onClick={this.back1}
                  
                >
                  <IconArrowBack />
                </IconButton>
                <div style={{ marginTop: '10px',marginLeft: '0px' }}>
                Please enter OTP and set new password
                </div>

              </div>
                
              </Typography>
            <form onSubmit={this.sendOtpAndPassword}>
              {/* Input - emailOrMobile */}
              <Grid item xs={12}>
                <TextField
                  name={'emailOrMobile'}
                  type={'emailOrMobile'}
                  value={nullToEmptyString(emailOrMobile)}
                  onChange={this.onType}
                  label={'Email'}
                  placeholder={'Enter Email'}
                  required={true}
                  margin={'dense'}
                  autoComplete={'off'}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="otp"
                  type="text"
                  value={otp}
                  onChange={this.onType}
                  label="OTP"
                  placeholder="Enter otp"
                  required
                  margin="dense"
                  autoComplete="off"
                  fullWidth
                  inputProps={{ maxLength: 4 }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  }}
                />
              </Grid>
              <Grid item xs={12} style={{marginBottom: 12, position: 'relative' }}>
              <TextField
                name={'password'}
                type={showPassword ? 'text' : 'password'}
                value={nullToEmptyString(password)}
                onChange={this.onType}
                label={'Password'}
                placeholder={'Enter password'}
                required={true}
                margin={'dense'}
                autoComplete={'off'}
                fullWidth
                variant='standard'
              />
              <IconButton
                onClick={this.togglePasswordVisibility} // Toggle password visibility
                edge="end"
                style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: 0 }}
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
              </Grid>
              
              <Grid item xs={12} style={{marginBottom: 12, position: 'relative' }}>
              <TextField
                name={'confirmPassword'}
                type={showPassword ? 'text' : 'password'} // Conditional rendering based on state
                value={nullToEmptyString(confirmPassword)}
                onChange={this.onType}
                label={'Password'}
                placeholder={'Enter password'}
                required={true}
                margin={'dense'}
                autoComplete={'off'}
                fullWidth
                variant='standard'
              />
              <IconButton
                onClick={this.togglePasswordVisibility} // Toggle password visibility
                edge="end"
                style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: 0 }}
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
              </Grid>

              {/* <Grid item xs={12}>
                <GoogleReCaptchaProvider
                  reCaptchaKey="6LeU-5gpAAAAAKIVKoagvGxf_MJrnncLylmbCyEx"
                >
                  <GoogleReCaptcha onVerify={this.recapchaLoaded} />
                </GoogleReCaptchaProvider>
              </Grid> */}

              {/* Button -  Save */}
              <Grid item xs={12} >
                <Button
                    style={{ marginInlineStart: 12 }}
                    type={"submit"}
                    aria-label={"Save"}
                    color={"primary"}
                    variant="contained"
                  >
                    Submit
                </Button>
              </Grid>
            </form>
          </div>
          :
          <div>
              <Typography
                variant={'subtitle1'}
                color={'inherit'}
                className={classes.heading}
              >
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <IconButton
                  className={classes.menuButton}
                  color="inherit"
                  onClick={this.back}
                >
                  <IconArrowBack />
                </IconButton>
                <div style={{ marginTop: '10px',marginLeft: '12px' }}>
                Please enter registered mail id
                </div>

              </div>

              </Typography>
            <form onSubmit={this.sendEmailId}>
              {/* Input - emailOrMobile */}
              
              <Grid item xs={12}>
                <TextField
                  name={'emailOrMobile'}
                  type={'emailOrMobile'}
                  value={nullToEmptyString(emailOrMobile)}
                  onChange={this.onType}
                  label={'Email '}
                  placeholder={'Enter Email '}
                  required={true}
                  margin={'dense'}
                  autoComplete={'off'}
                  fullWidth
                />
              </Grid>

              {/* <Grid item xs={12}>
                <GoogleReCaptchaProvider
                  reCaptchaKey="6LeU-5gpAAAAAKIVKoagvGxf_MJrnncLylmbCyEx"
                >
                  <GoogleReCaptcha onVerify={this.recapchaLoaded} />
                </GoogleReCaptchaProvider>
              </Grid> */}

              {/* Button -  Save */}
              <Grid item xs={12}>
                <Button
                    style={{ marginInlineStart: 12 }}
                  type={"submit"}
                  aria-label={"Save"}
                  color={"primary"}
                    variant="contained"
                >
                    Submit
                </Button>
              </Grid>
            </form>
          </div>
          }
        </Paper>
      </div>
    )
  }
}

// Component Properties
ForgotPassword.propTypes = {
  userForgotPasswordEmail: PropTypes.func.isRequired,
  adminForgotPasswordReset: PropTypes.func.isRequired,
  checkMail: PropTypes.func.isRequired,
  sendMail: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  messageShow: PropTypes.func.isRequired
}

export default connect(null, { userForgotPasswordEmail,adminForgotPasswordReset, checkMail , sendMail, messageShow })(withStyles(styles)(ForgotPassword))
