// App Imports
import params from '../../../setup/config/params'
import List from '../List'
import Detail from '../Detail'
import Update from '../Update'
import { API_URL, IMAGE_SOURCE_PATH } from '../../../setup/config/env'


export function getImageSource(image = '/default.jpg') {
  return `${ IMAGE_SOURCE_PATH }${ image }`
}

// Pages routes
export default {
  customerList: {
    path: (page = ':page') => `/customers/${ page }`,
    activePath: '/customer',
    component: List,
    auth: true,
    role: params.user.roles.admin.key
  },

  customerDetail: {
    path: (userId = ':userId') => (`/customer/detail/${ userId }`),
    component: Detail,
    auth: true,
    role: params.user.roles.admin.key
  },

  customerUpadte: {
    path: (userId = ':userId') => (`/customer/edit/${ userId }`),
    component: Update,
    auth: true,
    role: params.user.roles.admin.key
  }
}
