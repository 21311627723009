import React, { useState, useEffect } from 'react'
import { useParams, useHistory, Route, Switch, useLocation } from "react-router-dom";
import { Box, Typography, Divider, List, ListItem, ListItemText, Chip } from '@mui/material'
import { messageShow } from "../../common/api/actions"
import { useDispatch } from 'react-redux'
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import routes, { getImageSource } from "../api/routes";
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SentList from './SentList';

const ViewEvent = () => {
    const { id, type } = useParams()
    const location = useLocation();
    const [eventData, setEventData] = useState(location.state?.eventData || null)
    const dispatch = useDispatch()
    const history = useHistory();

    useEffect(() => {
        if (!eventData) {
            dispatch(messageShow("Event data not found. Redirecting to main page."))
            history.push(routes.messaging.path);
        }
    }, [eventData, dispatch, history])

    const BackButton = () => (
        <IconButton color='primary' onClick={() => history.goBack()} aria-label="Go back">
            <ArrowBackIcon/>
        </IconButton>
    );

    const getEventTypeHeading = () => {
        switch (type) {
            case 'email':
                return "Email";
            case 'push-notification':
                return "Push Notification";
            case 'fb-post':
                return "Facebook Post";
            default:
                return "Event";
        }
    };

    if (!eventData) return <div>Loading...</div>
        return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Switch>
        <Route exact path="/messaging/:type/view/:id" render={() => (
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: '100%', height:'auto', mt: 2 }}>
                <Box sx={{ width: { xs: '100%', md: '65%' }, pr: { md: 2 }, mb: { xs: 2, md: 0 } }}>
                    <Box sx={{ display: "flex", ml: 2, alignItems: "center", mb: 1 }}>
                        <BackButton/>
                        <Typography variant="subtitle2">{getEventTypeHeading()}: {eventData.name}</Typography>
                    </Box>
                    <Divider />
                    <List>
                        <ListItem>
                            <ListItemText primary="Date" secondary={new Date(eventData.date).toLocaleDateString()} />
                        </ListItem>
                        {type === "email" && (
                            <ListItem>
                                <ListItemText primary="Subject" secondary={eventData.subject} />
                            </ListItem>
                        )}
                        {(type==="fb-post" || type==="email") && (
                            <>
                                <ListItem>
                                    <ListItemText primary="Message" secondary={eventData.message} />
                                </ListItem>
                                {eventData.eventImg && (
                                    <ListItem>
                                        <img 
                                            src={getImageSource(`${eventData.eventImg}`)}
                                            style={{ maxHeight:"150px", maxWidth: "100%", objectFit: "contain" }}
                                            alt=''
                                        />
                                    </ListItem>
                                )}
                            </>
                        )}
                        {type==="push-notification" && (
                            <ListItem>
                                <ListItemText primary="Message" secondary={eventData.message} />
                            </ListItem>
                        )}
                    </List>
                </Box>
                <Box sx={{ width: { xs: '100%', md: '35%' }, p: 2 }}>
                    <Typography variant="subtitle2" gutterBottom>More Details</Typography>
                    <Divider />
                    <List>
                        {eventData.status && 
                            <ListItem>
                                <ListItemText primary="Status" secondary={eventData.status} />
                            </ListItem>
                        }
                        {eventData.for && eventData.for !== "age" && typeof eventData.for === 'string' && 
                            <ListItem>
                                <ListItemText primary="To" secondary={`${eventData.for.charAt(0).toUpperCase() + eventData.for.slice(1)} customers`} />
                            </ListItem>
                        }
                        {eventData.customerJoinDays!==0 && eventData.for === 'age' && 
                            <ListItem>
                                <ListItemText primary="To" secondary={`Customers joined in: ${eventData.customerJoinDays} Days`} />
                            </ListItem>
                        }
                        {eventData.totalCustomer !== undefined && 
                            <ListItem>
                                <ListItemText primary="Total Customers" secondary={eventData.totalCustomer} />
                            </ListItem>
                        }
                        {eventData.sendCount !== undefined && type !== 'fb-post' && 
                            <ListItem>
                                <ListItemText primary="Sent" secondary={eventData.sendCount} />
                            </ListItem>
                        }
                        {eventData.createdAt && 
                            <ListItem>
                                <ListItemText primary="Created At" secondary={new Date(eventData.createdAt).toLocaleString()} />
                            </ListItem>
                        }
                        <ListItem>
                            <ListItemText 
                                primary="Sent List" 
                                secondary={
                                    eventData.sentList && eventData.sentList.length > 0 ? (
                                        <Box>
                                            {eventData.sentList.slice(0, 2).map((email, index) => (
                                                <Chip key={index} label={email.email} style={{ margin: '2px' }} />
                                            ))}
                                            {eventData.sentList.length > 2 && (
                                                <Chip
                                                    label="..see more"
                                                    onClick={() => history.push(`/messaging/${type}/view/${id}/sent-list`)}
                                                    style={{ margin: '2px', cursor: 'pointer' }}
                                                />
                                            )}
                                        </Box>
                                    ) : (
                                        "No list to show"
                                    )
                                }
                            />
                        </ListItem>
                    </List>
                </Box>
            </Box>
        )} />
        <Route path="/messaging/:type/view/:id/sent-list" render={() => <SentList eventData={eventData} type="after" event={type}/>} />
        <Route path="/messaging/:type/view/:id/custom-emails" render={() => <SentList eventData={eventData} type="before"event={type}/>} />
    </Switch>
        </LocalizationProvider>
    )
}

export default ViewEvent