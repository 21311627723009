// Imports
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// UI Imports
import Grid from '@material-ui/core/Grid/Grid'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@mui/material/Button'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CheckIcon from '@mui/icons-material/Check';
import IconArrowBack from '@material-ui/icons/ArrowBack'
import IconClose from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import Tooltip from '@material-ui/core/Tooltip'

// App Imports
import { nullToEmptyString, slug, capitalizeFirstLetter } from '../../../setup/helpers'
import { upload, messageShow } from '../../common/api/actions'
import SectionPaper from '../../common/SectionPaper'
import Loading from '../../common/Loading'
import Image from '../../common/Image'
import { detail } from '../api/actions/query'
import { createOrUpdate } from '../api/actions/mutation'
import routes from '../api/routes'

// Component
class CreateOrUpdate extends Component {

  constructor(props) {
    super(props)

    this.pincode = {
      _id: '',
      pincode: '',
      description: '',
    }

    this.state = {
      isLoading: false,
      isLoadingSubmit: false,
      isUploadingFile: false,

      pincode: this.pincode
    }
  }

  componentDidMount() {
    const { match: { params: { pincodeId } } } = this.props

    if(pincodeId) {
      this.getPincode(pincodeId)
    }
  }

  getPincode = async (pincodeId) => {
    const { detail } = this.props

    this.isLoadingToggle(true)

    try {
      const { data } = await detail({ pincodeId })

      if(data.success) {
        this.setState({
          pincode: data.data
        })
      }
    } catch(error) {
      messageShow('There was some error. Please try again.')
    } finally {
      this.isLoadingToggle(false)
    }
  }

  onCreateOrUpdate = async event => {
    event.preventDefault()

    const { createOrUpdate, messageShow, history } = this.props

    const { pincode } = this.state

    this.isLoadingSubmitToggle(true)

    try {
      const { data } = await createOrUpdate({ pincode })


      this.isLoadingSubmitToggle(false)

      messageShow(data.message)

      if(data.success) {
        history.push(routes.pincodeList.path)
      }
    } catch (error) {
      this.isLoadingSubmitToggle(false)

      messageShow('Some error occurred. Please try again.')
    }
  }

  isLoadingToggle = isLoading => {
    this.setState({
      isLoading
    })
  }

  isLoadingSubmitToggle = isLoadingSubmit => {
    this.setState({
      isLoadingSubmit
    })
  }

  isUploadingFileToggle = isUploadingFile => {
    this.setState({
      isUploadingFile
    })
  }

  onType = ({ target: { name, value }}) => {
    const { pincode } = this.state

    pincode[name] = value

    this.setState({
      pincode
    })
  }

  render() {
    const { match: { params: { pincodeId } }, classes } = this.props
    const { pincode, isLoading, isLoadingSubmit } = this.state

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <Link to={routes.pincodeList.path}>
            <IconButton className={classes.menuButton} color="inherit">
              <IconArrowBack />
            </IconButton>
          </Link>

          <Typography variant="h6" color="inherit" className={classes.grow}>
            { pincodeId ? 'Edit' : 'Create' } Pincode
          </Typography>
        </Toolbar>

        <Grid item xs={12} lg={6}>
          <SectionPaper padding>
            {
              isLoading
                ? <Loading/>
                : <form onSubmit={this.onCreateOrUpdate}>
                    {/* Input - Pincode */}
                    <Grid item xs={12}>
                      <TextField
                        name={'pincode'}
                        value={nullToEmptyString(pincode.pincode)}
                        onChange={this.onType}
                        label={'Pincode'}
                        placeholder={'Enter Pincode'}
                        required={true}
                        margin={'dense'}
                        autoComplete={'off'}
                        fullWidth
                        autoFocus
                      />
                    </Grid>

                    {/* Input - Description */}
                    <Grid item xs={12}>
                      <TextField
                        name={'description'}
                        value={nullToEmptyString(pincode.description)}
                        onChange={this.onType}
                        label={'Description'}
                        placeholder={'Enter description'}
                        margin={'dense'}
                        autoComplete={'off'}
                        multiline
                        rowsMax={3}
                        rows={3}
                        fullWidth
                      />
                    </Grid>

                    {/* Button - Save */}
                    <Grid item xs={12} className={classes.buttonsContainer}>
                      <Link to={routes.pincodeList.path}>
                        <Tooltip title="Cancel" arrow>
                          <Button type={"button"} aria-label={"Close"} color={"error"} startIcon={<CancelIcon />}></Button>
                        </Tooltip>
                      </Link>

                      <Tooltip title="Save" arrow> 
                        <Button
                          type={"submit"}
                          aria-label={"Save"}
                          startIcon={<SaveIcon />}
                          color={"primary"}
                          disabled={isLoadingSubmit}
                        >
                        </Button>
                      </Tooltip>
                    </Grid>
                  </form>
            }
          </SectionPaper>
        </Grid>
      </div>
    )
  }
}

// Component Properties
CreateOrUpdate.propTypes = {
  detail: PropTypes.func.isRequired,
  createOrUpdate: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

export default connect(null, { detail, createOrUpdate, upload, messageShow })(withStyles(styles)(CreateOrUpdate))
