import React, { useState } from "react";
import { Toolbar, Typography} from "@material-ui/core";
import { Container, Col } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import routes from "./api/routes";
import { Route, Switch, useHistory } from "react-router-dom";
import ForecastAndCltv from "./components/ForecastAndCltv";
import Pricing from "./components/Pricing";
const Analytics = () => {
    const [currentTab, setCurrentTab] = useState("SalesForecast");
    const history = useHistory();
    const tabChange = (key) => {
        setCurrentTab(key);
        history.push(routes.analytics.path);
    };

    return (
        <>
            <Toolbar>
                <Typography variant="h6" color="inherit">
                    Analytics
                </Typography>
            </Toolbar>
                <Col style={{ padding: 0, minWidth: '100px', position: 'relative' }}>
                    <div style={{display: 'flex',justifyContent: 'space-between',alignItems: 'flex-end',}}>
                        <Tabs activeKey={currentTab} onSelect={tabChange} transition={false} style={{ flex: 1, paddingRight: '48px' }}>
                            <Tab eventKey="SalesForecast" title={<span style={{ fontSize: "15px" }}>Sales Forecast</span>}/>
                            <Tab eventKey="OrderForeCast" title={<span style={{ fontSize: "15px" }}> Order Forecast</span>}/>
                            <Tab eventKey="Pricing" title={<span style={{ fontSize: "15px" }}> Pricing</span>} />
                            <Tab eventKey="cltv" title={<span style={{ fontSize: "15px" }}> CLTV Model</span>} />
                        </Tabs>
                    </div>
                </Col>
            <section id="menu" className="mt-3">
                <Container fluid>
                    <Switch>
                        <Route exact path="/analytics">
                            {currentTab === "SalesForecast" && (
                                <ForecastAndCltv type={1} chartType="Sales Forecast"/>
                            )}
                            {currentTab === "OrderForeCast" && (
                                <ForecastAndCltv type={2} chartType="Order Forecast"/>
                            )}
                            {currentTab === "Pricing" && (
                                <Pricing />
                            )}
                            {currentTab === "cltv" && (
                                <ForecastAndCltv type={3} chartType="CLTV Model"/>
                            )}
                        </Route>
                    </Switch>
                </Container>
            </section>
        </>
    );
};
export default Analytics;
