// Imports
import axios from 'axios'
// App Imports
import { API_URL } from '../../../../setup/config/env'
import { MESSAGE_SHOW } from '../../../common/api/actions'
import { DASHBOARD_COUNT_GET_CACHE } from './cache-keys'
import {
  GET_DASHBOARD_COUNT_DONE, GET_DASHBOARD_COUNT_REQUEST, GET_DASHBOARD_COUNT_RESPONSE, LOGIN_REQUEST, LOGIN_RESPONSE, LOGOUT, SET_USER
} from './types'


// Actions


// Get customers
export function getUserDetails({ token }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'checkToken',
      params: { token }
    })
  }
}

// Check for register Email id
export function checkMail({ email }) {
  return async dispatch => {
    try {
      const { data } = await axios.post(API_URL, { operation: 'checkMailId', params: { email } })

      if (data.success) {
        dispatch({
          type: MESSAGE_SHOW,
          message: `${email} is registered email id `
        })
      } else {
        dispatch({
          type: MESSAGE_SHOW,
          message: `Please enter registered email id.`
        })
      }
      return data;
    } catch (e) {
      console.error(e)
    }
  }
}

// Login a user using credentials
export function login({ email, password }, isLoading = true) {
  return async dispatch => {
    dispatch({
      type: LOGIN_REQUEST,
      isLoading
    })

    dispatch({
      type: MESSAGE_SHOW,
      message: 'Please wait..'
    })

    try {
      const { data } = await axios.post(API_URL, {
        operation: 'userLoginAdmin',
        params: { email, password }
      })

      let message = ''

      if (data.success) {
        dispatch(setUser(data.data.token, data.data.user))

        setUserLocally(data.data.token, data.data.user)

        message = `Login successful. Welcome back, ${data.data.user.name}.`
      } else {
        message = data.message
      }

      dispatch({
        type: MESSAGE_SHOW,
        message
      })
    } catch (error) {
      dispatch({
        type: MESSAGE_SHOW,
        message: 'Please try again.'
      })
    } finally {
      dispatch({
        type: LOGIN_RESPONSE
      })
    }
  }
}

// Log out user and remove token from local (AsyncStorage)
export function logout() {
  return dispatch => {
    unsetUserLocally()

    dispatch({
      type: LOGOUT
    })
  }
}

// Set a user after login or using local (AsyncStorage) token
export function setUser(token, user) {
  if (token) {
    axios.defaults.headers.common['Authentication'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authentication'];
  }

  return {
    type: SET_USER,
    user
  }
}

// Set user token and info locally (AsyncStorage)
export function setUserLocally(token, user) {
  // Set token
  window.localStorage.setItem('token', token)
  window.localStorage.setItem('user', JSON.stringify(user))
}

// Unset user token and info locally (AsyncStorage)
export function unsetUserLocally() {
  // Remove token
  window.localStorage.removeItem('token')
  window.localStorage.removeItem('user')

  // Remove cached data
  for (let item of Object.keys({ ...window.localStorage })) {
    if (item.indexOf('CACHE.KEY.') !== -1) {
      window.localStorage.removeItem(item)
    }
  }
}

// Get dashboard counts
export function getDashboardCounts(isLoading = true) {
  return async dispatch => {
    // Caching
    try {
      const counts = JSON.parse(window.localStorage.getItem(DASHBOARD_COUNT_GET_CACHE))

      if (counts) {
        dispatch({
          type: GET_DASHBOARD_COUNT_RESPONSE,
          counts
        })
      } else {
        dispatch({
          type: GET_DASHBOARD_COUNT_REQUEST,
          isLoading
        })
      }
    } catch (e) {
      dispatch({
        type: GET_DASHBOARD_COUNT_REQUEST,
        isLoading
      })
    }

    try {
      const { data } = await axios.post(API_URL, {
        operation: 'userDashboardCounts'
      })

      if (!data.success) {
        dispatch({
          type: MESSAGE_SHOW,
          success: data.success,
          message: data.message
        })
      } else {
        const counts = data.data

        dispatch({
          type: GET_DASHBOARD_COUNT_RESPONSE,
          counts
        })

        window.localStorage.setItem(DASHBOARD_COUNT_GET_CACHE, JSON.stringify(counts))
      }
    } catch (error) {
      dispatch({
        type: MESSAGE_SHOW,
        success: false,
        message: error.message
      })
    } finally {
      dispatch({
        type: GET_DASHBOARD_COUNT_DONE,
        isLoading: false
      })
    }
  }
}

// Get customers
export function listCustomers({ page , filter, sortField, sortOrder}) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'userListCustomer',
      params: { page , filter, sortField, sortOrder}
    })
  }
}
export function listAllCustomers() {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'userListAllCustomer'
    })
  }
}

// Get vendors
export function listVendors({ page }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'userListVendor',
      params: { page }
    })
  }
}

// Get vendors all
export function listVendorsAll() {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'userListVendorAll'
    })
  }
}

// Get customers
export function detail({ userId }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'userDetail',
      params: { userId }
    })
  }
}

// Report Order Collection Statement
export function reportOrderCollection({ startDate, endDate, pincodeId, paymentType }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'userReportOrderCollection',
      params: { startDate, endDate, pincodeId, paymentType }
    })
  }
}

// Report Product Stock Statement
export function productReportStockCollection({ startDate, endDate, categoryId }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'productReportStockCollection',
      params: { startDate, endDate, categoryId }
    })
  }
}

export function pieChartCalculation({ startDate, endDate, pincodeId, paymentType }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'calculationPieChart',
      params: { startDate, endDate, pincodeId, paymentType }
    })
  }
}

export function barChartCalculation({ startDate, endDate, pincodeId, paymentType }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'calculationbarChart',
      params: { startDate, endDate, pincodeId, paymentType }
    })
  }
}

// Report Order Collection Statement
export function reportOrderPrepaidDeliveriesToday({ pincodeId }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'userReportOrderPrepaidDeliveriesToday',
      params: { pincodeId }
    })
  }
}

export function customerWalletReport({ startDate, endDate }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'customerWalletReport',
      params: { startDate, endDate }
    })
  }
}

export function productPurchaseReport({ startDate, endDate }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'productPurchaseReport',
      params: { startDate, endDate }
    })
  }
}
