// Imports
import { Grid, Table, withStyles } from "@material-ui/core";
import Button from "@mui/material/Button";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Anchor from "@material-ui/core/Link";
import { messageShow } from "../../../common/api/actions";
import SectionPaper from "../../../common/SectionPaper";
import styles from "./styles";
import InputBase from "@material-ui/core/InputBase";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Loading from "../../../common/Loading";
import BarChart from "./barChart";
import PieChart from "./pieChart";
import routes from "../../api/routes";
import PropTypes from "prop-types";
import { getAllEarnedPoints } from "../api/actions/query";

// Additional styles for the chart container
const chartStyles = (theme) => ({
  chartContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
  },
  chart: {
    flex: "1 1 50%", // Adjusted to 50% width
    height: 400,
  },
});

// Component
class Leaderboard extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      leaderBoardPoints: [],
      count: 0,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  refresh = async () => {
    const { getAllEarnedPoints, isLoading } = this.props;
    this.isLoadingToggle(isLoading);
    try {
      const { data } = await getAllEarnedPoints();
      if (data.success) {
        this.setState({
          leaderBoardPoints: data.data,
        });
      } else {
        messageShow(data.message);
      }
    } catch (error) {
      messageShow("There was some error. Please try again.");
    } finally {
      this.isLoadingToggle(false);
    }
  };

  isLoadingToggle = (isLoading) => {
    this.setState({
      isLoading,
    });
  };
  render() {
    const { leaderBoardPoints, isLoading } = this.state;
    const { classes } = this.props;
    const list = leaderBoardPoints;

    return (
      <>
        <SectionPaper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" style={{ width: 120 }}>
                  User Name
                </TableCell>
                <TableCell align="center" style={{ width: 50 }}>
                  RANK
                </TableCell>
                <TableCell align="center" style={{ width: 130 }}>
                  Mobile
                </TableCell>
                <TableCell align="center" style={{ width: 170 }}>
                  Total Earned Points
                </TableCell>
                <TableCell align="center" style={{ width: 150 }}>
                  Wallet Balance
                </TableCell>
                <TableCell align="center" style={{ width: 170 }}>
                  Redeemed Points
                </TableCell>
                <TableCell align="center" style={{ width: 190 }}>
                  No Of Rewards Used
                </TableCell>
              </TableRow>
            </TableHead>

            {isLoading ? (
              <Loading />
            ) : list.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell width={200}>No Data Found </TableCell>
                  {/* <EmptyMessage message={" No products found"} /> */}
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {list.map(
                  (
                    {
                      _id,
                      walletBalance,
                      totalEarnedPoints,
                      totalRedeemedPoints,
                      totalRewardsUsed,
                      user,
                    },
                    index
                  ) => (
                    <TableRow key={_id}>
                      <TableCell align="center">
                        <Link to={routes.LeaderBoardDetails.path(user._id)}>
                          <Anchor
                            component="span"
                            style={{ fontWeight: 500 }}
                            color={"primary"}
                          >
                            {user.name}
                          </Anchor>
                        </Link>
                      </TableCell>
                      <TableCell align="center">{index + 1}</TableCell>
                      {/* Rank starts from 1 */}
                      <TableCell align="center">{user.mobile}</TableCell>
                      <TableCell align="center">{totalEarnedPoints}</TableCell>
                      <TableCell align="center">{walletBalance}</TableCell>
                      <TableCell align="center">
                        {totalRedeemedPoints}
                      </TableCell>
                      <TableCell align="center">{totalRewardsUsed}</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            )}
          </Table>
        </SectionPaper>
        <SectionPaper>
          <Typography variant="h6">Charts</Typography>
          <div className={classes.chartContainer} style={{ display: "flex" }}>
            <div className={classes.chart} style={{ width: "45%" }}>
              <PieChart />
            </div>

            <div className={classes.chart} style={{ width: "45%" }}>
              <BarChart />
            </div>
          </div>
        </SectionPaper>
      </>
    );
  }
}

Leaderboard.propTypes = {
  getAllEarnedPoints: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default connect(null, { messageShow, getAllEarnedPoints })(
  withStyles(styles, chartStyles)(Leaderboard)
);
