// Imports
import axios from 'axios'

// App Imports
import { API_URL } from '../../../../setup/config/env'

// Signup vendor
export function signup({ name, email, mobile, password,role }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'userSignupVendor',
      params: { name, email, mobile, password, role }
    })
  }
}

// Publish toggle vendor
export function publishToggleVendor({ vendorId, isPublished }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'userPublishToggleVendor',
      params: { vendorId, isPublished }
    })
  }
}

// Remove vendor
export function removeVendor(data) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'userDeleteVendor',
      params: data
    })
  }
}

// Delete
export function deleteUser({ userId }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'userDeleteUser',
      params: { userId  }
    })
  }
}

export function updateDetail({ user }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'userProfileUpdate',
      params: user
    })
  }
}

// Send Email
export function sendMail(data) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'sendResetPasswordEmail',
      params: data
    })
  }
}

export function updatePassword({ token, password}) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'updatePassword',
      params: { token, password }
    })
  }
}

// Reset password using OTP
export function adminForgotPasswordReset({ emailOrMobile , otp , password }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'userForgotPasswordReset',
      params: { emailOrMobile, otp , password }
    })
  }
}

// get otp for password reset using email
export function userForgotPasswordEmail({ emailOrMobile }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'userForgotPasswordEmail',
      params: { emailOrMobile }
    })
  }
}